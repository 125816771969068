<template>
  <div class="row">
    <!--    <div class="col-lg-12" v-if="isSummaryLoading">-->
    <!--      &lt;!&ndash;      <div class="loader loader-backdrop loader-marketing" v-if="isSummaryLoading"></div>&ndash;&gt;-->
    <!--      <div v-if="isSummaryLoading"><Loader-spinner centered></Loader-spinner></div>-->
    <!--      <div class="card">-->
    <!--        <div class="card-header">-->
    <!--          <i class="glyphicons glyphicons-stats mr-0"></i>-->
    <!--          {{ $t('portalStats.stats') }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-3 mb-h input-group-for-location-select-in-PortalStatistics--filters" v-if="areLocationsVisible">

                                                                                                           <span
                                                                                                             class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location.data}"></i>
                </span>
          <SelectComponentV3ForUseInLocationSelectors

            v-if="showFilters"
            no-wrap-selected-option
            class="select-location-in-PortalStatistics--filters"
            enable-max-option-width
            :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
            small-size
            :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
            :show-delete-button="!isSelectedDefaultLocationForRequests"
            v-model="filters.location"
            track-by="name"
            option-id-name="id"
            :no-options-found="$t('general.noResultForSearch')"
            :async-function="requestLocations.bind(this)"
            :placeholder="$t('portalStats.selectLocation')"
            @select="changeLocation"
          >
            <template #dropdown-start>
              <div class="ml-h mt-1">
                <Switch-component
                   v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                  :disabled="isDisabledWithChilds"
                  @input="toggleWithChilds"
                  :label="$t('general.withChild')"
                  class=""
                  v-model="filters.with_childs"
                />
              </div>
            </template>
          </SelectComponentV3ForUseInLocationSelectors>


        </div>
        <div class="col-lg-3 mb-h "  :style="{'z-index: 9996':  !areLocationsVisible} ">
          <div class="input-group input-group-sm w-100">
                        <span class="input-group-addon">
              <i class="fa fa-file-o" />
            </span>
                            <Multi-select-component
                              v-if="showFilters"
                    v-model="selectedProfileInMultiselect"
                    class="multiselect-in-PortalStatistics--filters w-100"
                    option-id-name="id"
                    track-by="name"
                    :placeholder="$t('portalStats.selectProfile')"
                    :async-function="requestProfiles.bind(this)"
                    @select="changeProfile"
                  />
            </div>
        </div>
      </div>
      <div class="row">
              <div class="col-lg-4">
        <div class="filters__multiple">
          <div class="input-group input-group-sm filters__wlan flex-wrap mr-1">
            <WButton secondary outline sm
              v-if="isFiltersActive"
              @click.stop.prevent="resetAllFilters"
            >
              <i class="fa fa-close"></i>
              {{ $t('general.resetAllFilters') }}
            </WButton>
          </div>
        </div>
      </div>
      </div>

    </div>
    <div class="col-lg-12">
      <!--      <div class="loader loader-backdrop loader-marketing" v-if="isSummaryLoading"></div>-->
      <!--      <div v-if="isSummaryLoading"><Loader-spinner /></div>-->

      <div class="card">
        <div class="card-header">
          <i class="glyphicons glyphicons-pie-chart glyphicons--shrink mr-0"></i>
          {{ $t('portalStats.stats') }}
          <div class="float-right d-flex align-items-start">
            <div class="mr-1" style="min-width: 300px;">
                    <div class="input-group input-group filters__location">
                      <date-picker
                        v-if="showFilters"
                        class="datepicker_width_full datepicker_height_40"
                        v-model="drPickerDate"
                        type="date"
                        :onChange="handleDateChangeForStat.bind(this)"
                      />
                    </div>
            </div>
            <div>
                              <div class="w-100 d-flex flex-column justify-content-end download-button-in-PortalStatistics">
                  <download-extract-v2
                    newXlsAutoColumnsWidth
                    v-if="drPickerDate"
                    class=" float-right"
                    :data="formatAllStats()"
                    :data-for-excel="formatAllStatsToExcel()"
                    excel-file-type="xlsx"
                    :excel-file-name="getFileNameForDownloadFiles($t('downloadFilesNames.portalStatistic'))"
                    :filename="getFileNameForDownloadFiles($t('downloadFilesNames.portalStatistic'))"
                    :downloads-formats-for-show="{
                      oldCsv: true,
                      newCsv: false,
                      oldXls: false,
                      newXls: true,
                      pdf: true
                    }"
                  />
                </div>
            </div>
          </div>
        </div>
        <div class="card-block">
          <section>
<!--            <div class="col-12 mb-1">-->
<!--              <div class="row">-->
<!--                &lt;!&ndash; <div class="filter"> &ndash;&gt;-->
<!--                &lt;!&ndash; <label>{{ $t('portalStats.selectProfile') }}</label> &ndash;&gt;-->
<!--                <div class="col-6">-->
<!--                  <Multi-select-component-->
<!--                    v-model="selectedProfileInMultiselect"-->
<!--                    class="w-100"-->
<!--                    option-id-name="id"-->
<!--                    track-by="name"-->
<!--                    :placeholder="$t('portalStats.selectProfile')"-->
<!--                    :async-function="requestProfiles.bind(this)"-->
<!--                    @select="changeProfile"-->
<!--                  />-->
<!--                </div>-->
<!--                <div class="col-6">-->
<!--                  <SelectComponentV2-->
<!--                    v-if="showLocationFilter"-->
<!--                    no-wrap-selected-option-->
<!--                    class="select-location-in-PortalStatistics&#45;&#45;filters"-->
<!--                    enable-max-option-width-->
<!--                    small-size-->
<!--                    show-delete-button-->
<!--                    v-model="filters.location"-->
<!--                    track-by="name"-->
<!--                    option-id-name="id"-->
<!--                    :no-options-found="$t('general.noResultForSearch')"-->
<!--                    :async-function="requestLocations.bind(this)"-->
<!--                    :placeholder="$t('portalStats.selectLocation')"-->
<!--                    @select="changeLocation"-->
<!--                  >-->
<!--                    <template #dropdown-start>-->
<!--                      <div class="ml-h mt-1">-->
<!--                        <Switch-component-->
<!--                           :disabled="isDisabledWithChilds"-->
<!--                          @input="toggleWithChilds"-->
<!--                          :label="$t('general.withChild')"-->
<!--                          class=""-->
<!--                           v-model="filters.with_childs"-->
<!--                        />-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </SelectComponentV2>-->


<!--                </div>-->
<!--                &lt;!&ndash; </div> &ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-12">-->
<!--              <div class="row">-->
<!--                <div class="col-6">-->
<!--                  <div class="filter">-->
<!--                    <label>{{ $t('portalStats.selectRange') }}</label>-->
<!--                    <div class="input-group input-group filters__location">-->
<!--                      <date-picker-->
<!--                        class="datepicker_width_full datepicker_height_40"-->
<!--                        v-model="drPickerDate"-->
<!--                        type="date"-->
<!--                        :onChange="handleDateChangeForStat.bind(this)"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-3 submit-width_full">-->
<!--                  <button-->
<!--                    class="btn width_full"-->
<!--                    :class="{ 'btn-success': !submitButton, 'btn-default': submitButton }"-->
<!--                    :disabled="submitButton"-->
<!--                    type="button"-->
<!--                    @click="getRange(dataQuery)"-->
<!--                  >-->
<!--                    {{ $t('marketing.submit') }}-->
<!--                  </button>-->
<!--                </div>-->
<!--                <div class="col-3 d-flex flex-column justify-content-end submit-width_full">-->
<!--                  <download-extract-v2-->
<!--                    newXlsAutoColumnsWidth-->
<!--                    v-if="drPickerDate"-->
<!--                    class="mt-auto float-right"-->
<!--                    :data="formatAllStats()"-->
<!--                    :data-for-excel="formatAllStatsToExcel()"-->
<!--                    excel-file-type="xlsx"-->
<!--                    :excel-file-name="getFileNameForDownloadFiles($t('downloadFilesNames.portalStatistic'))"-->
<!--                    :filename="getFileNameForDownloadFiles($t('downloadFilesNames.portalStatistic'))"-->
<!--                    :downloads-formats-for-show="{-->
<!--                      oldCsv: true,-->
<!--                      newCsv: false,-->
<!--                      oldXls: false,-->
<!--                      newXls: true,-->
<!--                      pdf: true-->
<!--                    }"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </section>
          <!-- Real Time Data -->
          <div class="mt-0">
            <!--            <div class="text-center">-->
            <!--              <h2>{{ $t('portalStats.realTimeData') }}</h2>-->
            <!--            </div>-->

            <section class="graph-block">
              <div class="row pl-1 d-flex pr-1">
                <div
                  class="d-flex pl-q pr-q col-lg-5ths-for-portal-statistic-cards col-sm-5ths-for-portal-statistic-cards col-xs-5ths-for-portal-statistic-cards"
                >
                  <stats-card
                    v-if="!showSpinner.newUsers"
                    :data="newUsers"
                    :data-caption="$t('portalStats.newUsers')"
                    class="card-new animated fadeIn"
                  >
                    <template #icon><i class="icon-user-follow animated fadeIn"></i></template>
                    <template #body>
                      <div class="card-summary m-0">
                        <!--                        <Loader-spinner-small v-if="showSpinner.newUsers" centered></Loader-spinner-small>-->
                        <div class="h4 mb-0 mt-2 value--big animated fadeIn">
                          <span>{{ newUsers }}</span>
                          <numbers-difference
                            class="animated fadeIn"
                            :period-caption="`${$t('portalStats.comparePeriodsPeriod')}`"
                            :period-for-compare-caption="`${$t('portalStats.comparePeriodsPeriodForCompare')}`"
                            :visability-hidden="hideDifferenceData"
                            :difference="newUsersDifference"
                          ></numbers-difference>
                        </div>
                        <small class="text-uppercase value--title font-weight-bold text-muted animated fadeIn">
                          {{ $t('portalStats.newUsers') }}
                        </small>
                        <div class="h4 mb-0 mt-h value--big text-muted-light">
                          <small></small>
                        </div>
                      </div>
                    </template>
                  </stats-card>
                  <stats-card class="card-new animated fadeIn" v-else>
                    <template #body>
                      <div class="card-summary m-0 skeleton-placeholder-for-user-counters-in-portal-statistic">
                        <Loader-spinner-small centered></Loader-spinner-small>
                      </div>
                    </template>
                  </stats-card>
                </div>

                <div
                  class="d-flex pl-q pr-q col-lg-5ths-for-portal-statistic-cards col-sm-5ths-for-portal-statistic-cards col-xs-5ths-for-portal-statistic-cards"
                >
                  <stats-card
                    v-if="!showSpinner.notNewUsers"
                    :data="notNewUsers"
                    :data-caption="$t('portalStats.notNewUsers')"
                    class="card-returned animated fadeIn"
                  >
                    <template #icon><i class="icon-user-following animated fadeIn"></i></template>
                    <template #body>
                      <div class="card-summary m-0">
                        <!--                        <Loader-spinner-small v-if="showSpinner.notNewUsers" centered></Loader-spinner-small>-->
                        <div class="h4 mb-0 mt-2 value--big animated fadeIn">
                          <span>{{ notNewUsers }}</span>
                          <numbers-difference
                            class="animated fadeIn"
                            :period-caption="`${$t('portalStats.comparePeriodsPeriod')}`"
                            :period-for-compare-caption="`${$t('portalStats.comparePeriodsPeriodForCompare')}`"
                            :visability-hidden="hideDifferenceData"
                            :difference="notNewUsersDifference"
                          ></numbers-difference>
                        </div>
                        <small class="text-uppercase value--title font-weight-bold text-muted animated fadeIn">
                          {{ $t('portalStats.notNewUsers') }}
                        </small>
                        <div class="h4 mb-0 mt-h value--big text-muted-light">
                          <small></small>
                        </div>
                      </div>
                    </template>
                  </stats-card>
                  <stats-card class="card-returned animated fadeIn" v-else>
                    <template #body>
                      <div class="card-summary m-0 skeleton-placeholder-for-user-counters-in-portal-statistic">
                        <Loader-spinner-small centered></Loader-spinner-small>
                      </div>
                    </template>
                  </stats-card>
                </div>

                <div
                  class="d-flex pl-q pr-q col-lg-5ths-for-portal-statistic-cards col-sm-5ths-for-portal-statistic-cards col-xs-5ths-for-portal-statistic-cards"
                >
                  <stats-card
                    v-if="!showSpinner.uniqClients"
                    :data="uniqClients"
                    :data-caption="$t('portalStats.uniqClients')"
                    class="card-unique animated fadeIn"
                  >
                    <template #icon><i class="icon-people animated fadeIn"></i></template>
                    <template #body>
                      <div class="card-summary m-0">
                        <!--                        <Loader-spinner-small v-if="showSpinner.uniqClients" centered></Loader-spinner-small>-->
                        <div class="h4 mb-0 mt-2 value--big animated fadeIn">
                          <span>{{ uniqClients }}</span>
                          <numbers-difference
                            class="animated fadeIn"
                            :period-caption="`${$t('portalStats.comparePeriodsPeriod')}`"
                            :period-for-compare-caption="`${$t('portalStats.comparePeriodsPeriodForCompare')}`"
                            :visability-hidden="hideDifferenceData"
                            :difference="uniqClientsDifference"
                          ></numbers-difference>
                        </div>
                        <small class="text-uppercase value--title font-weight-bold text-muted animated fadeIn">
                          {{ $t('portalStats.uniqClients') }}
                        </small>
                        <div class="h4 mb-0 mt-h value--big text-muted-light">
                          <small></small>
                        </div>
                      </div>
                    </template>
                  </stats-card>
                  <stats-card class="card-unique animated fadeIn" v-else>
                    <template #body>
                      <div class="card-summary m-0 skeleton-placeholder-for-user-counters-in-portal-statistic">
                        <Loader-spinner-small centered></Loader-spinner-small>
                      </div>
                    </template>
                  </stats-card>
                </div>

                <div
                  class="d-flex pl-q pr-q col-lg-5ths-for-portal-statistic-cards col-sm-5ths-for-portal-statistic-cards col-xs-5ths-for-portal-statistic-cards"
                >
                  <stats-card
                    v-if="!showSpinner.totalConnections"
                    :data="totalConnections"
                    :data-caption="$t('portalStats.totalConnections')"
                    class="card-default animated fadeIn"
                  >
                    <template #icon><i class="icon-login animated fadeIn"></i></template>
                    <template #body>
                      <div class="card-summary m-0">
                        <!--                        <Loader-spinner-small v-if="showSpinner.totalConnections" centered></Loader-spinner-small>-->
                        <div class="h4 mb-0 mt-2 value--big animated fadeIn">
                          <span>{{ totalConnections }}</span>
                          <numbers-difference
                            class="animated fadeIn"
                            :period-caption="`${$t('portalStats.comparePeriodsPeriod')}`"
                            :period-for-compare-caption="`${$t('portalStats.comparePeriodsPeriodForCompare')}`"
                            :visability-hidden="hideDifferenceData"
                            :difference="totalConnectionsDifference"
                          ></numbers-difference>
                        </div>
                        <small class="text-uppercase value--title font-weight-bold text-muted animated fadeIn">
                          {{ $t('portalStats.totalConnections') }}
                        </small>
                        <div class="h4 mb-0 mt-h value--big text-muted-light">
                          <small></small>
                        </div>
                      </div>
                    </template>
                  </stats-card>
                  <stats-card class="card-default animated fadeIn" v-else>
                    <template #body>
                      <div class="card-summary m-0 skeleton-placeholder-for-user-counters-in-portal-statistic">
                        <Loader-spinner-small centered></Loader-spinner-small>
                      </div>
                    </template>
                  </stats-card>
                </div>
                <div
                  class="d-flex pl-q pr-q col-lg-5ths-for-portal-statistic-cards col-sm-5ths-for-portal-statistic-cards col-xs-5ths-for-portal-statistic-cards"
                >
                  <stats-card
                    v-if="!showSpinner.smsCost"
                    :data="smsCost"
                    :data-caption="$t('portalDashboard.smsCost')"
                    class="card-new animated fadeIn"
                  >
                    <template #icon><i class="icon-wallet animated fadeIn"></i></template>
                    <template #body>
                      <div class="card-summary m-0">
                        <!--                        <Loader-spinner-small v-if="showSpinner.newUsers" centered></Loader-spinner-small>-->
                        <div class="h4 mb-0 mt-2 value--big animated fadeIn">
                          <span>
                            {{ smsCost }}
                            <span class="sms-Cost-rubles">{{ $t('general.rub') }}</span>
                          </span>
                          <numbers-difference
                            class="animated fadeIn sms-Cost-numbers-difference"
                            :period-caption="`${$t('portalStats.comparePeriodsPeriod')}`"
                            :period-for-compare-caption="`${$t('portalStats.comparePeriodsPeriodForCompare')}`"
                            :visability-hidden="hideDifferenceData"
                            :difference="smsCostDifference"
                          ></numbers-difference>
                        </div>
                        <small class="text-uppercase value--title font-weight-bold text-muted animated fadeIn">
                          {{ $t('portalDashboard.smsCost') }}
                          <info :content="$t('portalStats.smsCostInfoMsg')" />
                        </small>
                        <div class="h4 mb-0 mt-h value--big text-muted-light">
                          <small></small>
                        </div>
                      </div>
                    </template>
                  </stats-card>
                  <stats-card class="card-new animated fadeIn" v-else>
                    <template #body>
                      <div class="card-summary m-0 skeleton-placeholder-for-user-counters-in-portal-statistic">
                        <Loader-spinner-small centered></Loader-spinner-small>
                      </div>
                    </template>
                  </stats-card>
                </div>
              </div>
              <div class="row pl-1 d-flex pr-1">
                <div
                  class="col-lg-12 col-md-12 d-flex justify-content-center flex-wrap align-items-start pl-q pr-q animated fadeIn"
                >
                  <div>
                    <WButton info
                      customClass="animated fadeIn"
                      v-if="showSelectPreviousPeriodButton"
                      @click="toggleSelectPreviousPeriodShow"
                    >
                      {{ $t('portalStats.showSelectPreviousPeriodBlockButton') }}
                      <i v-if="!showSelectPreviousPeriodBlock" class="icon-arrow-down ml-1"></i>
                      <i v-else class="icon-arrow-right ml-1"></i>
                    </WButton>
                  </div>
                  <div v-if="showSelectPreviousPeriodBlock" class="animated fadeIn">
                    <div class="d-flex justify-content-center align-items-center flex-wrap ml-3">
                      <div>
                        <label class="mb-0">{{ $t('portalStats.selectRange') }}:</label>
                      </div>
                      <div class="date-picker-for-another-period ml-1">
                        <div>
                          <div>
                            <date-picker
                              class="datepicker_width_full datepicker_height_select_another_period"
                              v-model="drPickerDateForPreviousPeriod"
                              type="date"
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <WButton :success="submitButton"
                            customClass="ml-1"
                            @click="comparePeriods"
                            :disabled="!submitButton"
                          >
                            {{ $t('portalStats.compareWithPreviousPeriodButton') }}
                          </WButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <hr />
            <!-- //NOTE: first visit -->
            <div class="d-flex justify-content-around align-content-start flex-wrap">
              <div v-if="!showSpinner.pieChartUsers" class="portal-statistics-block-real-time-data animated fadeIn">
                <section class="graph-block">
                  <h3 class="mt-2 mb-3 custom-move-right-block-real-time-data animated fadeIn">
                    {{ $t('portalStats.usersFull') }}
                    <div class="float-right save-png-button-for-portal-stats animated fadeIn">
                      <save-png-button-for-portal-stats
                        class="color-brand animated fadeIn"
                        id="firstVisitChartPie"
                        :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartUsers')}`)"
                      />
                    </div>
                    <download-extract-v2
                      newXlsAutoColumnsWidth
                      v-if="true"
                      smallDownloadButton
                      class="mt-auto float-right custom-move-left-block-real-time-data custom-download-button-size-in-portal-stats animated fadeIn"
                      :data="formatPieToCSV(firstVisitChartPie, $t('portalStats.users.type'))"
                      :data-for-excel="formatPieToExcel(firstVisitChartPie, $t('portalStats.users.type'))"
                      excel-file-type="xlsx"
                      :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartUsers')}`)"
                      :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartUsers')}`)"
                      :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
                    />
                  </h3>

                  <div class="row block-real-time-data-pie-and-table">
                    <!--                    <Loader-spinner-small :topmargin="'120px'" v-if="showSpinner.pieChartUsers"></Loader-spinner-small>-->
                    <div class="col-lg-6 col-md-6 animated fadeIn">
                      <highcharts :options="firstVisitChartPie" id="firstVisitChartPie"></highcharts>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <table
                        class="table portal-stats-table portal-statistics-block-real-time-data-table animated fadeIn"
                      >
                        <thead class="custom-max-height-for-table-in-portal-stats-thead animated fadeIn">
                          <tr>
                            <th class="type-cell-users">{{ $t('portalStats.usersForTable.type') }}</th>
                            <th>{{ $t('marketing.count') }}</th>
                            <th>{{ $t('marketing.percent') }}</th>
                          </tr>
                        </thead>
                        <tbody class="custom-max-height-for-table-in-portal-stats-tbody animated fadeIn">
                          <tr
                            v-if="firstVisitChartPie.series[0].data.length"
                            v-for="(firstVisit, index) in firstVisitChartPie.series[0].data"
                            :key="index"
                          >
                            <td v-if="firstVisit.name" class="vendor-cell">{{ firstVisit.name }}</td>
                            <td v-if="firstVisit.name">{{ firstVisit.amount }}</td>
                            <td v-if="firstVisit.name">{{ firstVisit.y.toFixed(2) }}%</td>
                          </tr>
                          <tr v-if="!firstVisitChartPie.series[0].data.length" class="text-center">
                            <td colspan="3">{{ $t('aps.noDataToDisplay') }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </div>
              <div
                v-else
                class="portal-statistics-block-real-time-data wrapper-for-skeleton-placeholder-pie-charts-in-portal-statistic animated fadeIn"
              >
                <div class="skeleton-placeholder-for-pie-charts-in-portal-statistic">
                  <Loader-spinner-small centered></Loader-spinner-small>
                </div>
              </div>
              <div v-if="!showSpinner.pieChartBrowser" class="portal-statistics-block-real-time-data animated fadeIn">
                <!-- //NOTE: browser -->
                <section class="graph-block">
                  <h3 class="mt-2 mb-3 custom-move-right-block-real-time-data animated fadeIn">
                    {{ $t('portalStats.browserFull') }}
                    <div class="float-right save-png-button-for-portal-stats animated fadeIn">
                      <save-png-button-for-portal-stats
                        class="color-brand animated fadeIn"
                        id="browserChartPie"
                        :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartBrowsers')}`)"
                      />
                    </div>
                    <download-extract-v2
                      newXlsAutoColumnsWidth
                      smallDownloadButton
                      v-if="true"
                      class="mt-auto float-right custom-move-left-block-real-time-data custom-download-button-size-in-portal-stats animated fadeIn"
                      :data="formatPieToCSV(browserChartPie, $t('portalStats.browserFull'))"
                      :data-for-excel="formatPieToExcel(browserChartPie, $t('portalStats.browserFull'))"
                      excel-file-type="xlsx"
                      :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartBrowsers')}`)"
                      :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartBrowsers')}`)"
                      :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
                    />
                  </h3>

                  <div class="row block-real-time-data-pie-and-table animated fadeIn">
                    <!--                    <Loader-spinner-small :topmargin="'120px'" v-if="showSpinner.pieChartBrowser"></Loader-spinner-small>-->
                    <div class="col-lg-6 col-md-6">
                      <highcharts :options="browserChartPie" id="browserChartPie"></highcharts>
                      <!--                      <SavePngButton-->
                      <!--                        class="save-icon color-brand"-->
                      <!--                        id="browserChartPie"-->
                      <!--                        :name="$t('portalStats.browser')"-->
                      <!--                      />-->
                    </div>

                    <div class="col-lg-6 col-md-6">
                      <table
                        class="table portal-stats-table portal-statistics-block-real-time-data-table animated fadeIn"
                      >
                        <thead class="custom-max-height-for-table-in-portal-stats-thead animated fadeIn">
                          <tr>
                            <th class="type-cell-browsers">{{ $t('portalStats.browser') }}</th>
                            <th>{{ $t('marketing.count') }}</th>
                            <th>{{ $t('marketing.percent') }}</th>
                          </tr>
                        </thead>
                        <tbody class="custom-max-height-for-table-in-portal-stats-tbody animated fadeIn">
                          <tr
                            v-if="browserChartPie.series[0].data.length"
                            v-for="(browser, index) in browserChartPie.series[0].data"
                            :key="index"
                          >
                            <td v-if="browser.name !== $t('portalStats.other')" class="browser-cell">
                              {{ browser.name }}
                            </td>
                            <td v-if="browser.name !== $t('portalStats.other')">{{ browser.amount }}</td>
                            <td v-if="browser.name !== $t('portalStats.other')">{{ browser.y.toFixed(2) }}%</td>
                          </tr>
                          <tr
                            v-if="browserChartPie.series[0].data.length"
                            v-for="(browser, index) in browserChartPie.series[0].data"
                            :key="browser.name"
                          >
                            <td v-if="browser.name === $t('portalStats.other')" class="browser-cell">
                              {{ $t('portalStats.other') }}
                            </td>
                            <td v-if="browser.name === $t('portalStats.other')">{{ browser.amount }}</td>
                            <td v-if="browser.name === $t('portalStats.other')">{{ browser.y.toFixed(2) }}%</td>
                          </tr>
                          <tr v-if="!browserChartPie.series[0].data.length" class="text-center">
                            <td colspan="3">{{ $t('aps.noDataToDisplay') }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </div>
              <div
                v-else
                class="portal-statistics-block-real-time-data wrapper-for-skeleton-placeholder-pie-charts-in-portal-statistic animated fadeIn"
              >
                <div class="skeleton-placeholder-for-pie-charts-in-portal-statistic">
                  <Loader-spinner-small centered></Loader-spinner-small>
                </div>
              </div>

              <!-- //NOTE: OS -->
              <div v-if="!showSpinner.pieChartOS" class="portal-statistics-block-real-time-data animated fadeIn">
                <section class="graph-block">
                  <h3 class="mt-2 mb-3 custom-move-right-block-real-time-data animated fadeIn">
                    {{ $t('portalStats.os') }}
                    <div class="float-right save-png-button-for-portal-stats animated fadeIn">
                      <save-png-button-for-portal-stats
                        class="color-brand animated fadeIn"
                        id="osChartPie"
                        :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartOperatingSystems')}`)"
                      />
                    </div>
                    <download-extract-v2
                      newXlsAutoColumnsWidth
                      smallDownloadButton
                      v-if="true"
                      class="mt-auto float-right custom-move-left-block-real-time-data custom-download-button-size-in-portal-stats animated fadeIn"
                      :data="formatPieToCSV(osChartPie, $t('portalStats.os'))"
                      :data-for-excel="formatPieToExcel(osChartPie, $t('portalStats.os'))"
                      excel-file-type="xlsx"
                      :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartOperatingSystems')}`)"
                      :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartOperatingSystems')}`)"
                      :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
                    />
                  </h3>

                  <div class="row block-real-time-data-pie-and-table animated fadeIn">
                    <!--                    <Loader-spinner-small :topmargin="'120px'" v-if="showSpinner.pieChartOS"></Loader-spinner-small>-->
                    <div class="col-lg-6 col-md-6">
                      <highcharts :options="osChartPie" id="osChartPie"></highcharts>
                      <!--                      <SavePngButton class="save-icon color-brand" id="osChartPie" :name="$t('portalStats.OS')" />-->
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <table
                        class="table portal-stats-table portal-statistics-block-real-time-data-table animated fadeIn"
                      >
                        <thead class="custom-max-height-for-table-in-portal-stats-thead animated fadeIn">
                          <tr>
                            <th class="type-cell">{{ $t('portalStats.OS') }}</th>
                            <th>{{ $t('marketing.count') }}</th>
                            <th>{{ $t('marketing.percent') }}</th>
                          </tr>
                        </thead>
                        <tbody class="custom-max-height-for-table-in-portal-stats-tbody animated fadeIn">
                          <tr
                            v-if="osChartPie.series[0].data.length"
                            v-for="(os, index) in osChartPie.series[0].data"
                            :key="index"
                          >
                            <td v-if="os.name !== $t('portalStats.other')" class="vendor-cell">{{ os.name }}</td>
                            <td v-if="os.name !== $t('portalStats.other')">{{ os.amount }}</td>
                            <td v-if="os.name !== $t('portalStats.other')">{{ os.y.toFixed(2) }}%</td>
                          </tr>
                          <tr
                            v-if="osChartPie.series[0].data.length"
                            v-for="(os, index) in osChartPie.series[0].data"
                            :key="os.name"
                          >
                            <td v-if="os.name === $t('portalStats.other')" class="vendor-cell">
                              {{ $t('portalStats.other') }}
                            </td>
                            <td v-if="os.name === $t('portalStats.other')">{{ os.amount }}</td>
                            <td v-if="os.name === $t('portalStats.other')">{{ os.y.toFixed(2) }}%</td>
                          </tr>
                          <tr v-if="!osChartPie.series[0].data.length" class="text-center">
                            <td colspan="3">{{ $t('aps.noDataToDisplay') }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </div>
              <div
                v-else
                class="portal-statistics-block-real-time-data wrapper-for-skeleton-placeholder-pie-charts-in-portal-statistic animated fadeIn"
              >
                <div class="skeleton-placeholder-for-pie-charts-in-portal-statistic">
                  <Loader-spinner-small centered></Loader-spinner-small>
                </div>
              </div>
              <!-- //NOTE: locale -->
              <div v-if="!showSpinner.pieChartLocale" class="portal-statistics-block-real-time-data animated fadeIn">
                <section class="graph-block">
                  <h3 class="mt-2 mb-3 custom-move-right-block-real-time-data animated fadeIn">
                    {{ $t('portalStats.locale.locale') }}
                    <div class="float-right save-png-button-for-portal-stats animated fadeIn">
                      <save-png-button-for-portal-stats
                        class="color-brand animated fadeIn"
                        id="localeChartPie"
                        :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartLocale')}`)"
                      />
                    </div>
                    <download-extract-v2
                      newXlsAutoColumnsWidth
                      smallDownloadButton
                      v-if="true"
                      class="mt-auto float-right custom-move-left-block-real-time-data custom-download-button-size-in-portal-stats animated fadeIn"
                      :data="formatPieToCSV(localeChartPie, $t('portalStats.locale.locale'))"
                      :data-for-excel="formatPieToExcel(localeChartPie, $t('portalStats.locale.locale'))"
                      excel-file-type="xlsx"
                      :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartLocale')}`)"
                      :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartLocale')}`)"
                      :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
                    />
                  </h3>
                  <div class="row block-real-time-data-pie-and-table animated fadeIn">
                    <!--                    <Loader-spinner-small :topmargin="'120px'" v-if="showSpinner.pieChartLocale"></Loader-spinner-small>-->
                    <div class="col-lg-6 col-md-6">
                      <highcharts :options="localeChartPie" id="localeChartPie"></highcharts>
                      <!--                      <SavePngButton-->
                      <!--                        class="save-icon color-brand"-->
                      <!--                        id="localeChartPie"-->
                      <!--                        :name="$t('portalStats.locale.locale')"-->
                      <!--                      />-->
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <table
                        class="table portal-stats-table portal-statistics-block-real-time-data-table animated fadeIn"
                      >
                        <thead class="custom-max-height-for-table-in-portal-stats-thead animated fadeIn">
                          <tr>
                            <th class="type-cell">{{ $t('portalStats.locale.locale') }}</th>
                            <th>{{ $t('marketing.count') }}</th>
                            <th>{{ $t('marketing.percent') }}</th>
                          </tr>
                        </thead>
                        <tbody class="custom-max-height-for-table-in-portal-stats-tbody animated fadeIn">
                          <tr
                            v-if="localeChartPie.series[0].data.length"
                            v-for="(locale, index) in localeChartPie.series[0].data"
                            :key="index"
                          >
                            <td v-if="locale.name !== $t('portalStats.other')" class="vendor-cell">
                              {{ locale.name }}
                            </td>
                            <td v-if="locale.name !== $t('portalStats.other')">{{ locale.amount }}</td>
                            <td v-if="locale.name !== $t('portalStats.other')">{{ locale.y.toFixed(2) }}%</td>
                          </tr>
                          <tr
                            v-if="localeChartPie.series[0].data.length"
                            v-for="(locale, index) in localeChartPie.series[0].data"
                            :key="locale.name"
                          >
                            <td v-if="locale.name === $t('portalStats.other')" class="vendor-cell">
                              {{ $t('portalStats.other') }}
                            </td>
                            <td v-if="locale.name === $t('portalStats.other')">{{ locale.amount }}</td>
                            <td v-if="locale.name === $t('portalStats.other')">{{ locale.y.toFixed(2) }}%</td>
                          </tr>
                          <tr v-if="!localeChartPie.series[0].data.length" class="text-center">
                            <td colspan="3">{{ $t('aps.noDataToDisplay') }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </div>
              <div
                v-else
                class="portal-statistics-block-real-time-data wrapper-for-skeleton-placeholder-pie-charts-in-portal-statistic animated fadeIn"
              >
                <div class="skeleton-placeholder-for-pie-charts-in-portal-statistic">
                  <Loader-spinner-small centered></Loader-spinner-small>
                </div>
              </div>
              <div v-if="!showSpinner.pieChartType" class="portal-statistics-block-real-time-data animated fadeIn">
                <!-- //NOTE: Device type -->
                <section class="graph-block">
                  <h3 class="mt-2 mb-3 custom-move-right-block-real-time-data animated fadeIn">
                    {{ $t('portalStats.typeFull') }}
                    <div class="float-right save-png-button-for-portal-stats animated fadeIn">
                      <save-png-button-for-portal-stats
                        class="color-brand animated fadeIn"
                        id="typeChartPie"
                        :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartDeviceType')}`)"
                      />
                    </div>
                    <download-extract-v2
                      newXlsAutoColumnsWidth
                      smallDownloadButton
                      v-if="true"
                      class="mt-auto float-right custom-move-left-block-real-time-data custom-download-button-size-in-portal-stats animated fadeIn"
                      :data="formatPieToCSV(typeChartPie, $t('portalStats.typeFull'))"
                      :data-for-excel="formatPieToExcel(typeChartPie, $t('portalStats.typeFull'))"
                      excel-file-type="xlsx"
                      :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartDeviceType')}`)"
                      :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartDeviceType')}`)"
                      :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
                    />
                  </h3>

                  <div class="row block-real-time-data-pie-and-table">
                    <!--                    <Loader-spinner-small :topmargin="'120px'" v-if="showSpinner.pieChartType"></Loader-spinner-small>-->
                    <div class="col-lg-6 col-md-6">
                      <highcharts :options="typeChartPie" id="typeChartPie"></highcharts>
                      <!--                      <SavePngButton class="save-icon color-brand" id="typeChartPie" :name="$t('portalStats.type')" />-->
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <table
                        class="table portal-stats-table portal-statistics-block-real-time-data-table animated fadeIn"
                      >
                        <thead class="custom-max-height-for-table-in-portal-stats-thead animated fadeIn">
                          <tr>
                            <th class="type-cell-type">{{ $t('portalStats.type') }}</th>
                            <th>{{ $t('marketing.count') }}</th>
                            <th>{{ $t('marketing.percent') }}</th>
                          </tr>
                        </thead>
                        <tbody class="custom-max-height-for-table-in-portal-stats-tbody animated fadeIn">
                          <tr
                            v-if="typeChartPie.series[0].data.length"
                            v-for="(type, index) in typeChartPie.series[0].data"
                            :key="index"
                          >
                            <td v-if="type.name !== $t('portalStats.other')" class="vendor-cell">{{ type.name }}</td>
                            <td v-if="type.name !== $t('portalStats.other')">{{ type.amount }}</td>
                            <td v-if="type.name !== $t('portalStats.other')">{{ type.y.toFixed(2) }}%</td>
                          </tr>
                          <tr
                            v-if="typeChartPie.series[0].data.length"
                            v-for="(type, index) in typeChartPie.series[0].data"
                            :key="type.name"
                          >
                            <td v-if="type.name === $t('portalStats.other')" class="vendor-cell">
                              {{ $t('portalStats.other') }}
                            </td>
                            <td v-if="type.name === $t('portalStats.other')">{{ type.amount }}</td>
                            <td v-if="type.name === $t('portalStats.other')">{{ type.y.toFixed(2) }}%</td>
                          </tr>
                          <tr v-if="!typeChartPie.series[0].data.length" class="text-center">
                            <td colspan="3">{{ $t('aps.noDataToDisplay') }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </div>
              <div
                v-else
                class="portal-statistics-block-real-time-data wrapper-for-skeleton-placeholder-pie-charts-in-portal-statistic animated fadeIn"
              >
                <div class="skeleton-placeholder-for-pie-charts-in-portal-statistic">
                  <Loader-spinner-small centered></Loader-spinner-small>
                </div>
              </div>
              <div v-if="!showSpinner.pieChartVendors" class="portal-statistics-block-real-time-data animated fadeIn">
                <!-- //NOTE: vendors -->
                <section class="graph-block">
                  <h3 class="mt-2 mb-3 custom-move-right-block-real-time-data animated fadeIn">
                    {{ $t('portalStats.vendorsFull') }}
                    <div class="float-right save-png-button-for-portal-stats animated fadeIn">
                      <save-png-button-for-portal-stats
                        class="color-brand animated fadeIn"
                        id="vendorsChartPie"
                        :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartVendors')}`)"
                      />
                    </div>
                    <download-extract-v2
                      newXlsAutoColumnsWidth
                      smallDownloadButton
                      v-if="true"
                      class="mt-auto float-right custom-move-left-block-real-time-data custom-download-button-size-in-portal-stats animated fadeIn"
                      :data="formatPieToCSV(vendorsChartPie, $t('portalStats.vendorsFull'))"
                      :data-for-excel="formatPieToExcel(vendorsChartPie, $t('portalStats.vendorsFull'))"
                      excel-file-type="xlsx"
                      :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartVendors')}`)"
                      :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartVendors')}`)"
                      :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
                    />
                  </h3>

                  <div class="row block-real-time-data-pie-and-table animated fadeIn">
                    <!--                    <Loader-spinner-small :topmargin="'120px'" v-if="showSpinner.pieChartVendors"></Loader-spinner-small>-->
                    <div class="col-lg-6 col-md-6">
                      <highcharts :options="vendorsChartPie" id="vendorsChartPie"></highcharts>
                      <!--                      <SavePngButton-->
                      <!--                        class="save-icon color-brand"-->
                      <!--                        id="vendorsChartPie"-->
                      <!--                        :name="$t('portalStats.vendors')"-->
                      <!--                      />-->
                    </div>

                    <div class="col-lg-6 col-md-6">
                      <table
                        class="table portal-stats-table portal-statistics-block-real-time-data-table animated fadeIn"
                      >
                        <thead class="custom-max-height-for-table-in-portal-stats-thead animated fadeIn">
                          <tr>
                            <th class="type-cell">{{ $t('portalStats.vendorsForTable') }}</th>
                            <th>{{ $t('marketing.count') }}</th>
                            <th>{{ $t('marketing.percent') }}</th>
                          </tr>
                        </thead>
                        <tbody class="custom-max-height-for-table-in-portal-stats-tbody animated fadeIn">
                          <tr
                            v-if="vendorsChartPie.series[0].data.length"
                            v-for="(vendor, index) in vendorsChartPie.series[0].data"
                            :key="index"
                          >
                            <td v-if="vendor.name !== $t('portalStats.other')" class="vendor-cell">
                              {{ vendor.name }}
                            </td>
                            <td v-if="vendor.name !== $t('portalStats.other')">{{ vendor.amount }}</td>
                            <td v-if="vendor.name !== $t('portalStats.other')">{{ vendor.y.toFixed(2) }}%</td>
                          </tr>
                          <tr
                            v-if="vendorsChartPie.series[0].data.length"
                            v-for="(vendor, index) in vendorsChartPie.series[0].data"
                            :key="vendor.name"
                          >
                            <td v-if="vendor.name === $t('portalStats.other')" class="vendor-cell">
                              {{ $t('portalStats.other') }}
                            </td>
                            <td v-if="vendor.name === $t('portalStats.other')">{{ vendor.amount }}</td>
                            <td v-if="vendor.name === $t('portalStats.other')">{{ vendor.y.toFixed(2) }}%</td>
                          </tr>
                          <tr v-if="!vendorsChartPie.series[0].data.length" class="text-center">
                            <td colspan="3">{{ $t('aps.noDataToDisplay') }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </div>
              <div
                v-else
                class="portal-statistics-block-real-time-data wrapper-for-skeleton-placeholder-pie-charts-in-portal-statistic animated fadeIn"
              >
                <div class="skeleton-placeholder-for-pie-charts-in-portal-statistic">
                  <Loader-spinner-small centered></Loader-spinner-small>
                </div>
              </div>
              <div v-if="!showSpinner.pieChartAuthZ" class="portal-statistics-block-real-time-data animated fadeIn">
                <!-- //NOTE: auth -->
                <section class="graph-block">
                  <h3 class="mt-2 mb-3 custom-move-right-block-real-time-data animated fadeIn">
                    {{ $t('portalStats.authFull') }}
                    <div class="float-right save-png-button-for-portal-stats animated fadeIn">
                      <save-png-button-for-portal-stats
                        class="color-brand animated fadeIn"
                        id="authChartPie"
                        :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartAuthorizationType')}`)"
                      />
                    </div>
                    <download-extract-v2
                      newXlsAutoColumnsWidth
                      smallDownloadButton
                      v-if="true"
                      class="mt-auto float-right custom-move-left-block-real-time-data custom-download-button-size-in-portal-stats animated fadeIn"
                      :data="formatPieToCSV(authChartPie, $t('portalStats.authFull'))"
                      :data-for-excel="formatPieToExcel(authChartPie, $t('portalStats.authFull'))"
                      excel-file-type="xlsx"
                      :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartAuthorizationType')}`)"
                      :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartAuthorizationType')}`)"
                      :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
                    />
                  </h3>

                  <div class="row block-real-time-data-pie-and-table animated fadeIn">
                    <!--                    <Loader-spinner-small :topmargin="'120px'" v-if="showSpinner.pieChartAuthZ"></Loader-spinner-small>-->
                    <div class="col-lg-6 col-md-6">
                      <highcharts :options="authChartPie" id="authChartPie"></highcharts>
                      <!--                      <SavePngButton-->
                      <!--                        class="save-icon color-brand"-->
                      <!--                        id="authChartPie"-->
                      <!--                        :name="$t('portalStats.auth.type')"-->
                      <!--                      />-->
                    </div>

                    <div class="col-lg-6 col-md-6">
                      <table
                        class="table portal-stats-table portal-statistics-block-real-time-data-table animated fadeIn"
                      >
                        <thead class="custom-max-height-for-table-in-portal-stats-thead animated fadeIn">
                          <tr>
                            <th class="type-cell-authz">{{ $t('portalStats.auth.type') }}</th>
                            <th>{{ $t('marketing.count') }}</th>
                            <th>{{ $t('marketing.percent') }}</th>
                          </tr>
                        </thead>
                        <tbody class="custom-max-height-for-table-in-portal-stats-tbody animated fadeIn">
                          <tr
                            v-if="authChartPie.series[0].data.length"
                            v-for="(auth, index) in authChartPie.series[0].data"
                            :key="index"
                          >
                            <td v-if="auth.name !== $t('portalStats.other')" class="auth-cell">{{ auth.name }}</td>
                            <td v-if="auth.name !== $t('portalStats.other')">{{ auth.amount }}</td>
                            <td v-if="auth.name !== $t('portalStats.other')">{{ auth.y.toFixed(2) }}%</td>
                          </tr>
                          <tr
                            v-if="authChartPie.series[0].data.length"
                            v-for="(auth, index) in authChartPie.series[0].data"
                            :key="auth.name"
                          >
                            <td v-if="auth.name === $t('portalStats.other')" class="auth-cell">
                              {{ $t('portalStats.other') }}
                            </td>
                            <td v-if="auth.name === $t('portalStats.other')">{{ auth.amount }}</td>
                            <td v-if="auth.name === $t('portalStats.other')">{{ auth.y.toFixed(2) }}%</td>
                          </tr>
                          <tr v-if="!authChartPie.series[0].data.length" class="text-center">
                            <td colspan="3">{{ $t('aps.noDataToDisplay') }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </div>
              <div
                v-else
                class="portal-statistics-block-real-time-data wrapper-for-skeleton-placeholder-pie-charts-in-portal-statistic animated fadeIn"
              >
                <div class="skeleton-placeholder-for-pie-charts-in-portal-statistic">
                  <Loader-spinner-small centered></Loader-spinner-small>
                </div>
              </div>
              <div v-if="!showSpinner.pieChartAuthN" class="portal-statistics-block-real-time-data animated fadeIn">
                <!-- //NOTE: authen -->
                <section class="graph-block">
                  <h3 class="mt-2 mb-3 custom-move-right-block-real-time-data animated fadeIn">
                    {{ $t('portalStats.authenFull') }}
                    <div class="float-right save-png-button-for-portal-stats animated fadeIn">
                      <save-png-button-for-portal-stats
                        class="color-brand animated fadeIn"
                        id="authenChartPie"
                        :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartAuthenticationType')}`)"
                      />
                    </div>
                    <download-extract-v2
                      newXlsAutoColumnsWidth
                      smallDownloadButton
                      v-if="true"
                      class="mt-auto float-right custom-move-left-block-real-time-data custom-download-button-size-in-portal-stats animated fadeIn"
                      :data="formatPieToCSV(authenChartPie, $t('portalStats.authenFull'))"
                      :data-for-excel="formatPieToExcel(authenChartPie, $t('portalStats.authenFull'))"
                      excel-file-type="xlsx"
                      :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartAuthenticationType')}`)"
                      :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticPieChartAuthenticationType')}`)"
                      :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
                    />
                  </h3>

                  <div class="row block-real-time-data-pie-and-table animated fadeIn">
                    <!--                    <Loader-spinner-small :topmargin="'120px'" v-if="showSpinner.pieChartAuthN"></Loader-spinner-small>-->
                    <div class="col-lg-6 col-md-6">
                      <highcharts :options="authenChartPie" id="authenChartPie"></highcharts>
                      <!--                      <SavePngButton-->
                      <!--                        class="save-icon color-brand"-->
                      <!--                        id="authenChartPie"-->
                      <!--                        :name="$t('portalStats.authen.type')"-->
                      <!--                      />-->
                    </div>

                    <div class="col-lg-6 col-md-6">
                      <table
                        class="table portal-stats-table portal-statistics-block-real-time-data-table animated fadeIn"
                      >
                        <thead class="custom-max-height-for-table-in-portal-stats-thead animated fadeIn">
                          <tr>
                            <th class="type-cell">{{ $t('portalStats.authen.type') }}</th>
                            <th>{{ $t('marketing.count') }}</th>
                            <th>{{ $t('marketing.percent') }}</th>
                          </tr>
                        </thead>
                        <tbody class="custom-max-height-for-table-in-portal-stats-tbody animated fadeIn">
                          <tr
                            v-if="authenChartPie.series[0].data.length"
                            v-for="(authen, index) in authenChartPie.series[0].data"
                            :key="index"
                          >
                            <td v-if="authen.name !== $t('portalStats.other')" class="authen-cell">
                              {{ authen.name }}
                            </td>
                            <td v-if="authen.name !== $t('portalStats.other')">{{ authen.amount }}</td>
                            <td v-if="authen.name !== $t('portalStats.other')">{{ authen.y.toFixed(2) }}%</td>
                          </tr>
                          <tr
                            v-if="authenChartPie.series[0].data.length"
                            v-for="(authen, index) in authenChartPie.series[0].data"
                            :key="authen.name"
                          >
                            <td v-if="authen.name === $t('portalStats.other')" class="authen-cell">
                              {{ $t('portalStats.other') }}
                            </td>
                            <td v-if="authen.name === $t('portalStats.other')">{{ authen.amount }}</td>
                            <td v-if="authen.name === $t('portalStats.other')">{{ authen.y.toFixed(2) }}%</td>
                          </tr>
                          <tr v-if="!authenChartPie.series[0].data.length" class="text-center">
                            <td colspan="3">{{ $t('aps.noDataToDisplay') }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </div>
              <div
                v-else
                class="portal-statistics-block-real-time-data wrapper-for-skeleton-placeholder-pie-charts-in-portal-statistic animated fadeIn"
              >
                <div class="skeleton-placeholder-for-pie-charts-in-portal-statistic">
                  <Loader-spinner-small centered></Loader-spinner-small>
                </div>
              </div>
              <div class="portal-statistics-block-real-time-data portal-statistics-block-real-time-data-hidden">
                <!-- //NOTE: hidden хак чтобы последняя строка в flex была сдвинута влево -->
              </div>
              <div class="portal-statistics-block-real-time-data portal-statistics-block-real-time-data-hidden">
                <!-- //NOTE: hidden хак чтобы последняя строка в flex была сдвинута влево -->
              </div>
            </div>
          </div>
          <hr />
          <!-- Not Real Time Data -->
          <div class="mt-1">
            <div class="text-center">
              <h2>{{ $t('portalStats.historyData') }}</h2>
              <p>{{ $t('portalStats.dataToYesterdayInclusive') }}</p>
            </div>

            <div class="d-flex justify-content-around align-content-start flex-wrap">
              <div v-if="!showSpinner.chartUsers" class="portal-statistics-block-non-real-time-data animated fadeIn">
                <!-- //NOTE: USERS -->
                <section class="graph-block">
                  <h3
                    class="mt-1 mb-1 custom-move-right-block-non-real-time-data animated fadeIn"
                  >
                    {{ $t('portalStats.newAndRepeatUsers') }}
                    <div class="float-right save-png-button-for-portal-stats animated fadeIn">
                      <save-png-button-for-portal-stats
                        class="color-brand animated fadeIn"
                        id="usersChartBar"
                        :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartNewAndRepeatUsers')}`)"
                      />
                    </div>
                    <download-extract-v2
                      newXlsAutoColumnsWidth
                      smallDownloadButton
                      v-if="true"
                      class="mt-auto float-right custom-move-left-block-non-real-time-data custom-download-button-size-in-portal-stats animated fadeIn"
                      :data="formatBarToCSV(usersChartBar, $t('portalStats.newAndRepeatUsers'))"
                      :data-for-excel="formatBarToExcel(usersChartBar, $t('portalStats.newAndRepeatUsers'))"
                      excel-file-type="xlsx"
                      :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartNewAndRepeatUsers')}`)"
                      :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartNewAndRepeatUsers')}`)"
                      :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
                    />
                  </h3>

                  <div class="row block-non-real-time-chart">
                    <!--                    <Loader-spinner-small :topmargin="'120px'" v-if="showSpinner.chartUsers"></Loader-spinner-small>-->
                    <div class="col-lg-12 col-md-12 animated fadeIn">
                      <highcharts :options="usersChartBar" id="usersChartBar"></highcharts>
                      <!--                      <SavePngButton-->
                      <!--                        class="save-icon color-brand"-->
                      <!--                        id="usersChartBar"-->
                      <!--                        :name="$t('portalStats.newAndRepeatUsers')"-->
                      <!--                      />-->
                    </div>
                  </div>
                </section>
              </div>
              <div
                v-else
                class="portal-statistics-block-non-real-time-data wrapper-for-skeleton-placeholder-line-charts-in-portal-statistic animated fadeIn"
              >
                <div class="skeleton-placeholder-for-line-charts-in-portal-statistic">
                  <Loader-spinner-small centered></Loader-spinner-small>
                </div>
              </div>

              <div v-if="!showSpinner.chartOS" class="portal-statistics-block-non-real-time-data animated fadeIn">
                <!-- //NOTE: OS -->
                <section class="graph-block">
                  <h3 class="mt-1 mb-1 custom-move-right-block-non-real-time-data animated fadeIn">
                    {{ $t('portalStats.os') }}
                    <div class="float-right save-png-button-for-portal-stats animated fadeIn">
                      <save-png-button-for-portal-stats
                        class="color-brand animated fadeIn"
                        id="osChartBar"
                        :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartOperatingSystems')}`)"
                      />
                    </div>
                    <download-extract-v2
                      newXlsAutoColumnsWidth
                      smallDownloadButton
                      v-if="true"
                      class="mt-auto float-right custom-move-left-block-non-real-time-data custom-download-button-size-in-portal-stats animated fadeIn"
                      :data="formatBarToCSV(osChartBar, $t('portalStats.os'))"
                      :data-for-excel="formatBarToExcel(osChartBar, $t('portalStats.os'))"
                      excel-file-type="xlsx"
                      :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartOperatingSystems')}`)"
                      :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartOperatingSystems')}`)"
                      :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
                    />
                  </h3>

                  <div class="row block-non-real-time-chart">
                    <!--                     <Loader-spinner-small :topmargin="'120px'" v-if="showSpinner.chartOS"></Loader-spinner-small>-->
                    <div class="col-lg-12 col-md-12 animated fadeIn">
                      <highcharts :options="osChartBar" id="osChartBar"></highcharts>
                      <!--                      <SavePngButton class="save-icon color-brand" id="osChartBar" :name="$t('portalStats.OS')" />-->
                    </div>
                  </div>
                </section>
              </div>
              <div
                v-else
                class="portal-statistics-block-non-real-time-data wrapper-for-skeleton-placeholder-line-charts-in-portal-statistic animated fadeIn"
              >
                <div class="skeleton-placeholder-for-line-charts-in-portal-statistic">
                  <Loader-spinner-small centered></Loader-spinner-small>
                </div>
              </div>
              <div v-if="!showSpinner.chartLocale" class="portal-statistics-block-non-real-time-data animated fadeIn">
                <!-- //NOTE: locale -->
                <section class="graph-block">
                  <h3 class="mt-1 mb-1 custom-move-right-block-non-real-time-data animated fadeIn">
                    {{ $t('portalStats.locale.locale') }}
                    <div class="float-right save-png-button-for-portal-stats animated fadeIn">
                      <save-png-button-for-portal-stats
                        class="color-brand animated fadeIn"
                        id="localeChartBar"
                        :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartLocale')}`)"
                      />
                    </div>
                    <download-extract-v2
                      newXlsAutoColumnsWidth
                      smallDownloadButton
                      v-if="true"
                      class="mt-auto float-right custom-move-left-block-non-real-time-data custom-download-button-size-in-portal-stats animated fadeIn"
                      :data="formatBarToCSV(localeChartBar, $t('portalStats.locale.locale'))"
                      :data-for-excel="formatBarToExcel(localeChartBar, $t('portalStats.locale.locale'))"
                      excel-file-type="xlsx"
                      :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartLocale')}`)"
                      :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartLocale')}`)"
                      :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
                    />
                  </h3>
                  <div class="row block-non-real-time-chart">
                    <!--                    <Loader-spinner-small :topmargin="'120px'" v-if="showSpinner.chartLocale"></Loader-spinner-small>-->
                    <div class="col-lg-12 col-md-12 animated fadeIn">
                      <highcharts :options="localeChartBar" id="localeChartBar"></highcharts>
                      <!--                      <SavePngButton-->
                      <!--                        class="save-icon color-brand"-->
                      <!--                        id="localeChartBar"-->
                      <!--                        :name="$t('portalStats.locale.locale')"-->
                      <!--                      />-->
                    </div>
                  </div>
                </section>
              </div>
              <div
                v-else
                class="portal-statistics-block-non-real-time-data wrapper-for-skeleton-placeholder-line-charts-in-portal-statistic animated fadeIn"
              >
                <div class="skeleton-placeholder-for-line-charts-in-portal-statistic">
                  <Loader-spinner-small centered></Loader-spinner-small>
                </div>
              </div>
              <div v-if="!showSpinner.chartType" class="portal-statistics-block-non-real-time-data animated fadeIn">
                <!-- //NOTE: Device type -->
                <section class="graph-block">
                  <h3 class="mt-1 mb-1 custom-move-right-block-non-real-time-data animated fadeIn">
                    {{ $t('portalStats.typeFull') }}
                    <div class="float-right save-png-button-for-portal-stats animated fadeIn">
                      <save-png-button-for-portal-stats
                        class="color-brand animated fadeIn"
                        id="typeChartBar"
                        :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartDeviceType')}`)"
                      />
                    </div>
                    <download-extract-v2
                      newXlsAutoColumnsWidth
                      smallDownloadButton
                      v-if="true"
                      class="mt-auto float-right custom-move-left-block-non-real-time-data custom-download-button-size-in-portal-stats animated fadeIn"
                      :data="formatBarToCSV(typeChartBar, $t('portalStats.typeFull'))"
                      :data-for-excel="formatBarToExcel(typeChartBar, $t('portalStats.typeFull'))"
                      excel-file-type="xlsx"
                      :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartDeviceType')}`)"
                      :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartDeviceType')}`)"
                      :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
                    />
                  </h3>

                  <div class="row block-non-real-time-chart">
                    <!--                    <Loader-spinner-small :topmargin="'120px'" v-if="showSpinner.chartType"></Loader-spinner-small>-->
                    <div class="col-lg-12 col-md-12 animated fadeIn">
                      <highcharts :options="typeChartBar" id="typeChartBar"></highcharts>
                      <!--                      <SavePngButton class="save-icon color-brand" id="typeChartBar" :name="$t('portalStats.type')" />-->
                    </div>
                  </div>
                </section>
              </div>
              <div
                v-else
                class="portal-statistics-block-non-real-time-data wrapper-for-skeleton-placeholder-line-charts-in-portal-statistic animated fadeIn"
              >
                <div class="skeleton-placeholder-for-line-charts-in-portal-statistic">
                  <Loader-spinner-small centered></Loader-spinner-small>
                </div>
              </div>
              <div v-if="!showSpinner.chartVendors" class="portal-statistics-block-non-real-time-data animated fadeIn">
                <!-- //NOTE: vendors -->
                <section class="graph-block">
                  <h3 class="mt-1 mb-1 custom-move-right-block-non-real-time-data animated fadeIn">
                    {{ $t('portalStats.vendorsFull') }}
                    <div class="float-right save-png-button-for-portal-stats animated fadeIn">
                      <save-png-button-for-portal-stats
                        class="color-brand animated fadeIn"
                        id="vendorsChartBar"
                        :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartVendors')}`)"
                      />
                    </div>
                    <download-extract-v2
                      newXlsAutoColumnsWidth
                      smallDownloadButton
                      v-if="true"
                      class="mt-auto float-right custom-move-left-block-non-real-time-data custom-download-button-size-in-portal-stats animated fadeIn"
                      :data="formatBarToCSV(vendorsChartBar, $t('portalStats.vendorsFull'))"
                      :data-for-excel="formatBarToExcel(vendorsChartBar, $t('portalStats.vendorsFull'))"
                      excel-file-type="xlsx"
                      :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartVendors')}`)"
                      :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartVendors')}`)"
                      :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
                    />
                  </h3>

                  <div class="row block-non-real-time-chart">
                    <!--                    <Loader-spinner-small :topmargin="'120px'" v-if="showSpinner.chartVendors"></Loader-spinner-small>-->
                    <div class="col-lg-12 col-md-12 animated fadeIn">
                      <highcharts :options="vendorsChartBar" id="vendorsChartBar"></highcharts>
                      <!--                      <SavePngButton-->
                      <!--                        class="save-icon color-brand"-->
                      <!--                        id="vendorsChartBar"-->
                      <!--                        :name="$t('portalStats.vendors')"-->
                      <!--                      />-->
                    </div>
                  </div>
                </section>
              </div>
              <div
                v-else
                class="portal-statistics-block-non-real-time-data wrapper-for-skeleton-placeholder-line-charts-in-portal-statistic animated fadeIn"
              >
                <div class="skeleton-placeholder-for-line-charts-in-portal-statistic">
                  <Loader-spinner-small centered></Loader-spinner-small>
                </div>
              </div>
              <div v-if="!showSpinner.chartAuthZ" class="portal-statistics-block-non-real-time-data animated fadeIn">
                <!-- //NOTE: auth -->
                <section class="graph-block">
                  <h3 class="mt-1 mb-1 custom-move-right-block-non-real-time-data animated fadeIn">
                    {{ $t('portalStats.authFull') }}
                    <div class="float-right save-png-button-for-portal-stats animated fadeIn">
                      <save-png-button-for-portal-stats
                        class="color-brand animated fadeIn"
                        id="authChartBar"
                        :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartAuthorizationType')}`)"
                      />
                    </div>
                    <download-extract-v2
                      newXlsAutoColumnsWidth
                      smallDownloadButton
                      v-if="true"
                      class="mt-auto float-right custom-move-left-block-non-real-time-data custom-download-button-size-in-portal-stats animated fadeIn"
                      :data="formatBarToCSV(authChartBar, $t('portalStats.authFull'))"
                      :data-for-excel="formatBarToExcel(authChartBar, $t('portalStats.authFull'))"
                      excel-file-type="xlsx"
                      :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartAuthorizationType')}`)"
                      :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartAuthorizationType')}`)"
                      :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
                    />
                  </h3>

                  <div class="row block-non-real-time-chart">
                    <!--                    <Loader-spinner-small :topmargin="'120px'" v-if="showSpinner.chartAuthZ"></Loader-spinner-small>-->
                    <div class="col-lg-12 col-md-12 animated fadeIn">
                      <highcharts :options="authChartBar" id="authChartBar"></highcharts>
                      <!--                      <SavePngButton-->
                      <!--                        class="save-icon color-brand"-->
                      <!--                        id="authChartBar"-->
                      <!--                        :name="$t('portalStats.auth.type')"-->
                      <!--                      />-->
                    </div>
                  </div>
                </section>
              </div>
              <div
                v-else
                class="portal-statistics-block-non-real-time-data wrapper-for-skeleton-placeholder-line-charts-in-portal-statistic animated fadeIn"
              >
                <div class="skeleton-placeholder-for-line-charts-in-portal-statistic">
                  <Loader-spinner-small centered></Loader-spinner-small>
                </div>
              </div>
              <div v-if="!showSpinner.chartAuthN" class="portal-statistics-block-non-real-time-data animated fadeIn">
                <!-- //NOTE: authen -->
                <section class="graph-block">
                  <h3 class="mt-1 mb-1 custom-move-right-block-non-real-time-data animated fadeIn">
                    {{ $t('portalStats.authenFull') }}
                    <div class="float-right save-png-button-for-portal-stats animated fadeIn">
                      <save-png-button-for-portal-stats
                        class="color-brand animated fadeIn"
                        id="authenChartBar"
                        :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartAuthenticationType')}`)"
                      />
                    </div>
                    <download-extract-v2
                      newXlsAutoColumnsWidth
                      smallDownloadButton
                      v-if="true"
                      class="mt-auto float-right custom-move-left-block-non-real-time-data custom-download-button-size-in-portal-stats animated fadeIn"
                      :data="formatBarToCSV(authenChartBar, $t('portalStats.authenFull'))"
                      :data-for-excel="formatBarToExcel(authenChartBar, $t('portalStats.authenFull'))"
                      excel-file-type="xlsx"
                      :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartAuthenticationType')}`)"
                      :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalStatistic')}-${$t('downloadFilesNames.portalStatisticLineChartAuthenticationType')}`)"
                      :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
                    />
                  </h3>

                  <div class="row block-non-real-time-chart">
                    <!--                    <Loader-spinner-small :topmargin="'120px'" v-if="showSpinner.chartAuthN"></Loader-spinner-small>-->
                    <div class="col-lg-12 col-md-12 animated fadeIn">
                      <highcharts :options="authenChartBar" id="authenChartBar"></highcharts>
                      <!--                      <SavePngButton-->
                      <!--                        class="save-icon color-brand"-->
                      <!--                        id="authenChartBar"-->
                      <!--                        :name="$t('portalStats.authen.type')"-->
                      <!--                      />-->
                    </div>
                  </div>
                </section>
              </div>
              <div
                v-else
                class="portal-statistics-block-non-real-time-data wrapper-for-skeleton-placeholder-line-charts-in-portal-statistic animated fadeIn"
              >
                <div class="skeleton-placeholder-for-line-charts-in-portal-statistic">
                  <Loader-spinner-small centered></Loader-spinner-small>
                </div>
              </div>

              <div class="portal-statistics-block-real-time-data portal-statistics-block-non-real-time-data-hidden">
                <!-- //NOTE: hidden хак чтобы последняя строка в flex была сдвинута влево -->
              </div>
              <div class="portal-statistics-block-real-time-data portal-statistics-block-non-real-time-data-hidden">
                <!-- //NOTE: hidden хак чтобы последняя строка в flex была сдвинута влево -->
              </div>
              <div class="portal-statistics-block-real-time-data portal-statistics-block-non-real-time-data-hidden">
                <!-- //NOTE: hidden хак чтобы последняя строка в flex была сдвинута влево -->
              </div>
              <div class="portal-statistics-block-real-time-data portal-statistics-block-non-real-time-data-hidden">
                <!-- //NOTE: hidden хак чтобы последняя строка в flex была сдвинута влево -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Multiselect } from 'vue-multiselect';
import NumbersDifference from '../../components/UI/NumbersDifference.vue';
import MultiSelectComponent from '../../components/Universal/select/multi-select-component.vue';
// import SelectComponent from '../../components/Universal/select/select-component.vue';
import CheckboxComponent from '../../components/Universal/Checkbox-component.vue';
import DatePicker from '../../components/date-picker.vue';
import locationService from '../../services/locationService';
import portalProfilesService from '../../services/portalProfilesService';
import portalStatsService from '../../services/portalStatsService';
import SavePngButton from '../../components/save-png-button.vue';
import SavePngButtonForPortalStats from '../../components/save-png-button-for-portal-stats.vue';
import StatsCard from '../../components/Universal/stats-card.vue';
import helpers from '../../helpers';
import Info from '../../components/Universal/info-icon.vue';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

export default {
  name: 'PortalStats',
  components: {
    Multiselect,
    MultiSelectComponent,
    // SelectComponent,
    SavePngButton,
    DatePicker,
    StatsCard,
    CheckboxComponent,
    SavePngButtonForPortalStats,
    NumbersDifference,
    Info,
    SelectComponentV2,
    SwitchComponent,
    SelectComponentV3ForUseInLocationSelectors
  },
  data() {
    return {
      showFilters: true,
      showingPreviousPeriodData: false,
      showSelectPreviousPeriodBlock: false,
      showSelectPreviousPeriodButton: true,
      selectedProfileInMultiselect: [],
      submitButton: true,
      totalConnections: 0,
      uniqClients: 0,
      newUsers: 0,
      notNewUsers: 0,
      smsCost: 0,
      totalConnectionsForPreviousPeriod: 0,
      uniqClientsForPreviousPeriod: 0,
      newUsersForPreviousPeriod: 0,
      notNewUsersForPreviousPeriod: 0,
      smsCostForPreviousPeriod: 0,
      filters: {
        profiles: { name: this.$t('portalStats.allProfiles') },
        location: {},
        with_childs: true
      },
      dataQuery: [
        'os',
        'locale',
        'type',
        'vendor',
        'all_visit',
        'unique_visit',
        'first_visit',
        'auth',
        'authen',
        'browser'
      ],
      drPickerDate: {
        startDate: moment().startOf('day').valueOf() - 7 * 24 * 60 * 60 * 1000, // семь дней назад
        endDate: moment().endOf('day').valueOf()
      },
      drPickerDateForPreviousPeriod: {
        startDate: moment().startOf('day').valueOf() - 7 * 24 * 60 * 60 * 1000 - 7 * 24 * 60 * 60 * 1000, // семь дней назад от семи дней назад
        endDate: moment().startOf('day').valueOf() - 7 * 24 * 60 * 60 * 1000 - 1
      },
      vendorsChartPie: {
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        subtitle: {},
        plotOptions: {
          pie: {
            size: '100%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.y:.1f}%'
            }
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px"><b>{series.name}</b></span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.amount}</b> ({point.y:.1f}% of total)<br/>'
        },
        series: [
          {
            name: this.$t('portalStats.vendorsFull'),
            colorByPoint: true,
            data: []
          }
        ],
        drilldown: {
          series: []
        }
      },
      osChartPie: {
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        subtitle: {},
        plotOptions: {
          pie: {
            size: '100%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.y:.1f}%'
            }
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px"><b>{series.name}</b></span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.amount}</b> ({point.y:.1f}% of total)<br/>'
        },
        series: [
          {
            name: this.$t('portalStats.os'),
            colorByPoint: true,
            data: []
          }
        ],
        drilldown: {
          series: []
        }
      },
      typeChartPie: {
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        subtitle: {},
        plotOptions: {
          pie: {
            size: '100%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.y:.1f}%'
            }
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px"><b>{series.name}</b></span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.amount}</b> ({point.y:.1f}% of total)<br/>'
        },
        series: [
          {
            name: this.$t('portalStats.typeFull'),
            colorByPoint: true,
            data: []
          }
        ],
        drilldown: {
          series: []
        }
      },
      localeChartPie: {
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        subtitle: {},
        plotOptions: {
          pie: {
            size: '100%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.y:.1f}%'
            }
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px"><b>{series.name}</b></span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.amount}</b> ({point.y:.1f}% of total)<br/>'
        },
        series: [
          {
            name: this.$t('portalStats.locale.locale'),
            colorByPoint: true,
            data: []
          }
        ],
        drilldown: {
          series: []
        }
      },
      authChartPie: {
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        subtitle: {},
        plotOptions: {
          pie: {
            size: '100%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.y:.1f}%'
            }
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px"><b>{series.name}</b></span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.amount}</b> ({point.y:.1f}% of total)<br/>'
        },
        series: [
          {
            name: this.$t('portalStats.auth.type'),
            colorByPoint: true,
            data: []
          }
        ],
        drilldown: {
          series: []
        }
      },
      authenChartPie: {
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        subtitle: {},
        plotOptions: {
          pie: {
            size: '100%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.y:.1f}%'
            }
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px"><b>{series.name}</b></span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.amount}</b> ({point.y:.1f}% of total)<br/>'
        },
        series: [
          {
            name: this.$t('portalStats.authen.type'),
            colorByPoint: true,
            data: []
          }
        ],
        drilldown: {
          series: []
        }
      },
      browserChartPie: {
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        subtitle: {},
        plotOptions: {
          pie: {
            size: '100%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.y:.1f}%'
            }
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px"><b>{series.name}</b></span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.amount}</b> ({point.y:.1f}% of total)<br/>'
        },
        series: [
          {
            name: this.$t('portalStats.browser'),
            colorByPoint: true,
            data: []
          }
        ],
        drilldown: {
          series: []
        }
      },
      firstVisitChartPie: {
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        subtitle: {},
        plotOptions: {
          pie: {
            size: '100%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.y:.1f}%'
            }
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px"><b>{series.name}</b></span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.amount}</b> ({point.y:.1f}% of total)<br/>'
        },
        series: [
          {
            name: this.$t('portalStats.users.type'),
            colorByPoint: true,
            data: []
          }
        ],
        drilldown: {
          series: []
        }
      },
      usersChartBar: {
        time: {
          useUTC: false
        },
        chart: {
          // type: 'column',
          type: 'spline',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          text: ''
        },
        subtitle: {
          // text: this.$t('statistics.pan')
        },
        xAxis: {
          categories: [],
          crosshair: true
        },
        yAxis: {
          // min: 0,
          allowDecimals: false,
          title: {
            text: ''
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px"><b>{point.key}</b></span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b> {point.y}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: []
      },
      vendorsChartBar: {
        time: {
          useUTC: false
        },
        chart: {
          // type: 'column',
          type: 'spline',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          text: ''
        },
        subtitle: {
          // text: this.$t('statistics.pan')
        },
        xAxis: {
          categories: [],
          crosshair: true
        },
        yAxis: {
          // min: 0,
          allowDecimals: false,
          title: {
            text: ''
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px"><b>{point.key}</b></span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b> {point.y}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: []
      },
      osChartBar: {
        time: {
          useUTC: false
        },
        chart: {
          // type: 'column',
          type: 'spline',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          text: ''
        },
        subtitle: {
          // text: this.$t('statistics.pan')
        },
        xAxis: {
          categories: [],
          crosshair: true
        },
        yAxis: {
          // min: 0,
          allowDecimals: false,
          title: {
            text: ''
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px"><b>{point.key}</b></span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b> {point.y}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: []
      },
      typeChartBar: {
        time: {
          useUTC: false
        },
        chart: {
          // type: 'column',
          type: 'spline',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          text: ''
        },
        subtitle: {
          // text: this.$t('statistics.pan')
        },
        xAxis: {
          categories: [],
          crosshair: true
        },
        yAxis: {
          // min: 0,
          allowDecimals: false,
          title: {
            text: ''
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px"><b>{point.key}</b></span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b> {point.y}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: []
      },
      localeChartBar: {
        time: {
          useUTC: false
        },
        chart: {
          // type: 'column',
          type: 'spline',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          text: ''
        },
        subtitle: {
          // text: this.$t('statistics.pan')
        },
        xAxis: {
          categories: [],
          crosshair: true
        },
        yAxis: {
          // min: 0,
          allowDecimals: false,
          title: {
            text: ''
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px"><b>{point.key}</b></span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b> {point.y}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: []
      },
      authChartBar: {
        time: {
          useUTC: false
        },
        chart: {
          // type: 'column',
          type: 'spline',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          text: ''
        },
        subtitle: {
          // text: this.$t('statistics.pan')
        },
        xAxis: {
          categories: [],
          crosshair: true
        },
        yAxis: {
          // min: 0,
          allowDecimals: false,
          title: {
            text: ''
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px"><b>{point.key}</b></span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b> {point.y}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: []
      },
      authenChartBar: {
        time: {
          useUTC: false
        },
        chart: {
          // type: 'column',
          type: 'spline',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          text: ''
        },
        subtitle: {
          // text: this.$t('statistics.pan')
        },
        xAxis: {
          categories: [],
          crosshair: true
        },
        yAxis: {
          // min: 0,
          allowDecimals: false,
          title: {
            text: ''
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px"><b>{point.key}</b></span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b> {point.y}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: []
      }
    };
  },
  watch: {
    drPickerDate: {
      deep: true,
      handler() {
        this.hideSelectPreviousPeriodBlockWhenfiltersChanged();
      }
    },
    selectedProfileInMultiselect: {
      deep: true,
      handler() {
        this.hideSelectPreviousPeriodBlockWhenfiltersChanged();
      }
    },
    filters: {
      deep: true,
      handler() {
        this.hideSelectPreviousPeriodBlockWhenfiltersChanged();
      }
    }
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    requestsStatusForPortalStatistic() {
      // данные по статусу обычных запросов
      return this.$store.state.activeRequestsForPortalStatisticPage;
    },
    requestsStatusForPortalStatisticForPreviousPeriod() {
      // данные по статусу запросов для предыдущего периода
      return this.$store.state.activeRequestsForPortalStatisticPageForPreviousPeriod;
    },
    isFiltersActive() {
      return (typeof this.filters.location === 'object' && Object.keys(this.filters.location).length) ||
        (Array.isArray(this.selectedProfileInMultiselect) && this.selectedProfileInMultiselect.length !== 0) ||
        this.drPickerDate.startDate !== moment().startOf('day').valueOf() - 7 * 24 * 60 * 60 * 1000 ||
        this.drPickerDate.endDate !== moment().endOf('day').valueOf()
    },
    showSpinner() {
      // отвечает за отображение спиннеров в болоках со статистикой
      return {
        // блоки со счетичками пользователей и соединений и стоимости СМС
        newUsers: this.isDataLoading.newUsers || this.isDataLoading.newUsersForPreviousPeriod,
        notNewUsers: this.isDataLoading.notNewUsers || this.isDataLoading.notNewUsersForPreviousPeriod,
        uniqClients: this.isDataLoading.uniqClients || this.isDataLoading.uniqClientsForPreviousPeriod,
        totalConnections: this.isDataLoading.totalConnections || this.isDataLoading.totalConnectionsForPreviousPeriod,
        smsCost: this.isDataLoading.smsCost || this.isDataLoading.smsCostForPreviousPeriod,
        // блоки с графиками-пончиками
        pieChartUsers: this.isDataLoading.pieChartUsers,
        pieChartBrowser: this.isDataLoading.pieChartBrowser,
        pieChartOS: this.isDataLoading.pieChartOS,
        pieChartLocale: this.isDataLoading.pieChartLocale,
        pieChartType: this.isDataLoading.pieChartType,
        pieChartVendors: this.isDataLoading.pieChartVendors,
        pieChartAuthZ: this.isDataLoading.pieChartAuthZ,
        pieChartAuthN: this.isDataLoading.pieChartAuthN,
        // блоки с графиками-линиями
        chartUsers: this.isDataLoading.chartUsers,
        chartOS: this.isDataLoading.chartOS,
        chartLocale: this.isDataLoading.chartLocale,
        chartType: this.isDataLoading.chartType,
        chartVendors: this.isDataLoading.chartVendors,
        chartAuthZ: this.isDataLoading.chartAuthZ,
        chartAuthN: this.isDataLoading.chartAuthN
      };
    },

    isDataLoading() {
      // возвращает информацию о том, какие из запросов данных выполняются (или не выполняются)
      return {
        // запросы с данными для счетичков пользователей и соединений и смс
        newUsers: this.requestsStatusForPortalStatistic.pie.first_visit,
        notNewUsers: this.requestsStatusForPortalStatistic.pie.first_visit,
        uniqClients: this.requestsStatusForPortalStatistic.pie.unique_visit,
        totalConnections: this.requestsStatusForPortalStatistic.pie.all_visit,
        smsCost: this.$store.state.getSMSCostForRangeForStatisticRequestExecuting,
        // запросы с данными для счетичков пользователей и соединений за предыдущий период и смс
        newUsersForPreviousPeriod: this.requestsStatusForPortalStatisticForPreviousPeriod.pie.first_visit,
        notNewUsersForPreviousPeriod: this.requestsStatusForPortalStatisticForPreviousPeriod.pie.first_visit,
        uniqClientsForPreviousPeriod: this.requestsStatusForPortalStatisticForPreviousPeriod.pie.unique_visit,
        totalConnectionsForPreviousPeriod: this.requestsStatusForPortalStatisticForPreviousPeriod.pie.all_visit,
        smsCostForPreviousPeriod: this.$store.state.getSMSCostForPreviousPeriodRangeForStatisticRequestExecuting,
        // запросы с данными для графиков-пончиков
        pieChartUsers: this.requestsStatusForPortalStatistic.pie.first_visit,
        pieChartBrowser: this.requestsStatusForPortalStatistic.pie.browser,
        pieChartOS: this.requestsStatusForPortalStatistic.pie.os,
        pieChartLocale: this.requestsStatusForPortalStatistic.pie.locale,
        pieChartType: this.requestsStatusForPortalStatistic.pie.type,
        pieChartVendors: this.requestsStatusForPortalStatistic.pie.vendor,
        pieChartAuthZ: this.requestsStatusForPortalStatistic.pie.auth,
        pieChartAuthN: this.requestsStatusForPortalStatistic.pie.authen,
        // запросы с данными для графиков-линий
        chartUsers: this.requestsStatusForPortalStatistic.timeseries.first_visit,
        chartOS: this.requestsStatusForPortalStatistic.timeseries.os,
        chartLocale: this.requestsStatusForPortalStatistic.timeseries.locale,
        chartType: this.requestsStatusForPortalStatistic.timeseries.type,
        chartVendors: this.requestsStatusForPortalStatistic.timeseries.vendor,
        chartAuthZ: this.requestsStatusForPortalStatistic.timeseries.auth,
        chartAuthN: this.requestsStatusForPortalStatistic.timeseries.authen
      };
    },
    hideDifferenceData() {
      return !this.showingPreviousPeriodData || !this.submitButton;
    },
    totalConnectionsDifference() {
      return this.compareStatsValues(this.totalConnections, this.totalConnectionsForPreviousPeriod);
    },
    uniqClientsDifference() {
      return this.compareStatsValues(this.uniqClients, this.uniqClientsForPreviousPeriod);
    },
    newUsersDifference() {
      return this.compareStatsValues(this.newUsers, this.newUsersForPreviousPeriod);
    },
    notNewUsersDifference() {
      return this.compareStatsValues(this.notNewUsers, this.notNewUsersForPreviousPeriod);
    },
    smsCostDifference() {
      return this.compareStatsValues(this.smsCost, this.smsCostForPreviousPeriod);
    },
    seletedTimePeriodRange() {
      return this.drPickerDate.endDate - this.drPickerDate.startDate;
    },
    startTimestampForPreviousPeriod() {
      return this.drPickerDate.startDate - this.seletedTimePeriodRange;
    },
    stopTimesampForPreviousPeriod() {
      return this.drPickerDate.endDate - this.seletedTimePeriodRange - 1;
    },
    isDisabledWithChilds() {
      if (!this.filters.location.id || this.filters.location.id === '') {
        return true;
      }
      return false;
    },
    locationsList() {
      const result = [];
      let baseLocation = {};
      const userBaseLocation = JSON.parse(localStorage.getItem('userData')).base_location;
      // const locations = helpers.sortLocationsList(JSON.parse(JSON.stringify(this.$store.state.locationsList)));
      const locations = JSON.parse(JSON.stringify(this.$store.getters.locationsListSorted));
      // console.log(userBaseLocation)
      locations.forEach((location) => {
        if (location.id === userBaseLocation) {
          // this.filters.location = { name: location.name, id: location.id };
          baseLocation = { name: location.name, id: location.id };
          // console.log(baseLocation)
          return;
        }
        result.push({ name: location.name, id: location.id });
      });
      result.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      result.unshift(baseLocation);
      result.unshift({ name: this.$t('portalStats.allLocations'), id: '' });
      // console.log(result)
      return result;
    },
    selectedProfileInMultiselectForRequest() {
      const result = [];
      if (this.selectedProfileInMultiselect.length === 0) {
        return result;
      }
      for (const profile of this.selectedProfileInMultiselect) {
        result.push(profile.id);
      }
      return result;
    },
    portalProfilesListForFilterMultiselect() {
      const list =
        this.$store.state.portalProfilesList && this.$store.state.portalProfilesList.length ?
          this.$store.state.portalProfilesList :
          [];
      const listForMultiselect = JSON.parse(JSON.stringify(list));
      if (listForMultiselect.length && listForMultiselect[0].id) {
      } else {
        listForMultiselect.shift();
      }
      listForMultiselect.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return listForMultiselect;
    },
    isSummaryLoading() {
      return this.$store.state.loadingSummary;
    },
    portalProfilesList() {
      const list =
        this.$store.state.portalProfilesList && this.$store.state.portalProfilesList.length ?
          this.$store.state.portalProfilesList :
          [];
      if (list.length && list[0].id) {
        list.unshift({ name: this.$t('portalStats.allProfiles') });
      }
      return list;
    }
  },
  methods: {
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    hideSelectPreviousPeriodBlockWhenfiltersChanged() {
      this.hidePreviousPeriodData();
      // this.showSelectPreviousPeriodBlock = false;
    },
    redrawFilters(){
      this.showFilters = false;
      this.$nextTick(()=>{
        this.showFilters = true;
      })
    },
    // resetAllFilters() {
    //   this.filters = {
    //     profiles: {name: this.$t('portalStats.allProfiles')},
    //     location: {},
    //     with_childs: true
    //   }
    //   this.selectedProfileInMultiselect = [];
    //   this.drPickerDate = {
    //     startDate: moment().startOf('day').valueOf() - 7 * 24 * 60 * 60 * 1000, // семь дней назад
    //     endDate: moment().endOf('day').valueOf()
    //   }
    //   this.redrawFilters();
    //   this.getRange(this.dataQuery)
    // },
    resetAllFilters() {
      this.filters = {
        profiles: {name: this.$t('portalStats.allProfiles')},
        // location: {},
        // with_childs: true
      }
      this.selectedProfileInMultiselect = [];
      this.drPickerDate = {
        startDate: moment().startOf('day').valueOf() - 7 * 24 * 60 * 60 * 1000, // семь дней назад
        endDate: moment().endOf('day').valueOf()
      }
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = {};
        this.filters.with_childs = true;
      } else {
        this.filters.location = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
      }
      this.redrawFilters();
      this.getRange(this.dataQuery)
    },
    showPreviousPeriodData() {
      this.showingPreviousPeriodData = true;
    },
    hidePreviousPeriodData() {
      this.showingPreviousPeriodData = false;
    },
    comparePeriods() {
      this.getRangeForPreviousPeriod();
      this.hidePreviousPeriodData();
      this.$nextTick(() => {
        this.showPreviousPeriodData();
      });
    },
    toggleSelectPreviousPeriodShow() {
      this.showSelectPreviousPeriodButton = false;
      this.$nextTick(() => {
        this.showSelectPreviousPeriodButton = true;
      });
      this.showSelectPreviousPeriodBlock = !this.showSelectPreviousPeriodBlock;
      if (this.showSelectPreviousPeriodBlock === false) {
        this.hidePreviousPeriodData();
      }
    },
    compareStatsValues(currentVal, previousVal) {
      const result = {};
      let differenceDirection;
      let differencePercents;
      const differenceAmount = currentVal - previousVal;
      if (differenceAmount < 0) {
        differenceDirection = 'loss';
      } else if (differenceAmount > 0) {
        differenceDirection = 'grow';
      } else {
        differenceDirection = 'none';
      }
      if (previousVal === 0) {
        differencePercents = false;
      } else {
        differencePercents = 100 - (currentVal / previousVal) * 100;
      }
      // особый случай, чтобы в интерфейс выводился 0
      if (previousVal === 0 && currentVal === 0) {
        differencePercents = 0;
      }
      result.currentVal = currentVal;
      result.previousVal = previousVal;
      result.differenceDirection = differenceDirection;
      result.differenceAmount = differenceAmount;
      if (differencePercents === false) {
        result.differencePercents = false;
      } else {
        result.differencePercents = Math.abs(parseInt(differencePercents));
      }

      return result;
    },
    async requestProfiles(searchQuery) {
      // return portalProfilesService.requestProfiles(searchQuery);
      return portalProfilesService.requestProfilesFilteredByLocationAndWithChilds(this, searchQuery);
    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
     //  return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    // changeLocation(selectedLocation) {
    //   if (selectedLocation.id === '') {
    //     this.filters.with_childs = true;
    //   }
    //   this.submitButton = false;
    // },
    changeLocation(selectedLocation) {
      if (!selectedLocation) {
        this.filters.with_childs = true;
        this.filters.location = {}
      }
      this.submitButton = false;
       this.getRange(this.dataQuery)
    },
    toggleWithChilds() {
      // this.filters.with_childs = !this.filters.with_childs;
      this.submitButton = false;
      this.$nextTick(() => {
        this.getRange(this.dataQuery)
      })

    },
    getRange(subtype) {
      this.hidePreviousPeriodData();
      // portalStatsService.getSMSCostForRangeForStatisticSection(this); // подтягиваем данные по цене СМС
      portalStatsService.getSMSCostForRangeForStatisticSection(this, true); // подтягиваем данные по цене СМС
      // this.showSelectPreviousPeriodBlock = false;
      this.submitButton = true;
      subtype.forEach((el) => {
        portalStatsService.getRange(this, 'pie', el);
        const piesOnly = ['all_visit', 'unique_visit', 'browser'];
        if (!piesOnly.includes(el)) {
          portalStatsService.getRange(this, 'timeseries', el);
        }
      });
    },
    getRangeForPreviousPeriod() {
      // portalStatsService.getSMSCostForPreviousPeriodRangeForStatisticSection(this); // подтягиваем данные по цене СМС за предидущий период
      portalStatsService.getSMSCostForPreviousPeriodRangeForStatisticSection(this, true); // подтягиваем данные по цене СМС за предидущий период
      const subtype = ['all_visit', 'unique_visit', 'first_visit'];
      // this.submitButton = true;
      subtype.forEach((el) => {
        portalStatsService.getRangeForPreviousPeriod(this, 'pie', el);
        // const piesOnly = ['all_visit', 'unique_visit', 'browser'];
        // if (!piesOnly.includes(el)) {
        //   portalStatsService.getRangeForPreviousPeriod(this, 'timeseries', el);
        // }
      });
    },
    changeProfile() {
      this.submitButton = false;
      this.getRange(this.dataQuery)
    },
    handleDateChangeForStat() {
      this.submitButton = false;
       this.getRange(this.dataQuery)
    },
    setTotalConnections(amount) {
      this.totalConnections = amount;
    },
    setUniqClients(amount) {
      this.uniqClients = amount;
    },
    setNewUsers(amount) {
      this.newUsers = amount;
    },
    setNotNewUsers(amount) {
      this.notNewUsers = amount;
    },
    setTotalConnectionsForPreviousPeriod(amount) {
      this.totalConnectionsForPreviousPeriod = amount;
    },
    setUniqClientsForPreviousPeriod(amount) {
      this.uniqClientsForPreviousPeriod = amount;
    },
    setNewUsersForPreviousPeriod(amount) {
      this.newUsersForPreviousPeriod = amount;
    },
    setNotNewUsersForPreviousPeriod(amount) {
      this.notNewUsersForPreviousPeriod = amount;
    },
    formatBarToCSV(graphData, graphName = '') {
      if (graphData.series.length === 0) {
        return [];
      }
      const firstRow = {};
      firstRow[this.$t('portalStats.type')] = graphName; // это добавил чтобы сдвинуть заголовки влево
      const lastRow = {}; // это добавил чтобы визуально отделять в сводном документе категории (например ОС и Языки)
      lastRow[this.$t('portalStats.type')] = ''; // это добавил чтобы визуально отделять в сводном документе категории (например ОС и Языки)
      const result = graphData.series.map((el) => {
        const row = {};
        graphData.xAxis.categories.forEach((date, index, array) => {
          row[this.$t('portalStats.type')] = el.name;
          row[date] = el.data[index];
          // Делаем строки с названием каждой статистики (заголовок по центру) раньше былло так
          // if (Math.round(array.length / 2) - 1 === index) {
          //   firstRow[this.$t('portalStats.type')] = '';
          //   firstRow[date] = graphName;
          // } else {
          //   firstRow[this.$t('portalStats.type')] = '';
          //   firstRow[date] = '';
          // }
          firstRow[date] = ''; // это добавил чтобы сдвинуть заголовки влево
          lastRow[date] = ''; // это добавил чтобы визуально отделять в сводном документе категории (например ОС и Языки)
        });
        return row;
      });
      return [firstRow, ...result, lastRow];
      // return [];
    },
    formatPieToCSV(graphData, graphName = '') {
      if (graphData.series[0].data.length === 0) {
        return [];
      }
      let name = 'Type';
      const count = this.$t('portalStats.count');
      const percent = this.$t('portalStats.percent');
      if (graphName === this.$t('portalStats.usersFull')) {
        name = this.$t('portalStats.users.type');
      }
      if (graphName === this.$t('portalStats.browserFull')) {
        name = this.$t('portalStats.browser');
      }
      if (graphName === this.$t('portalStats.os')) {
        name = this.$t('portalStats.OS');
      }
      if (graphName === this.$t('portalStats.locale.locale')) {
        name = this.$t('portalStats.locale.locale');
      }
      if (graphName === this.$t('portalStats.typeFull')) {
        name = this.$t('portalStats.type');
      }
      if (graphName === this.$t('portalStats.vendorsFull')) {
        name = this.$t('portalStats.vendors');
      }
      if (graphName === this.$t('portalStats.authFull')) {
        name = this.$t('portalStats.authenZ');
      }
      if (graphName === this.$t('portalStats.authenFull')) {
        name = this.$t('portalStats.authenFull');
      }

      const result = [];
      const chartName = {};
      chartName[name] = '';
      // chartName[count] = graphName || '';
      chartName[count] = '';
      chartName[percent] = '';
      result.push(chartName);
      for (const item of graphData.series[0].data) {
        const itemObj = {};
        itemObj[name] = '';
        if (item.name) {
          itemObj[name] = item.name;
        }
        itemObj[count] = '';
        if (item.amount) {
          itemObj[count] = item.amount;
        }
        itemObj[percent] = '';
        if (item.y) {
          itemObj[percent] = item.y.toFixed(2);
        }
        result.push(itemObj);
      }
      return result;
    },
    formatAllStats() {
      return [
        ...this.formatBarToCSV(this.usersChartBar, this.$t('portalStats.newAndRepeatUsers')),
        ...this.formatBarToCSV(this.osChartBar, this.$t('portalStats.os')),
        ...this.formatBarToCSV(this.localeChartBar, this.$t('portalStats.locale.locale')),
        ...this.formatBarToCSV(this.typeChartBar, this.$t('portalStats.typeFull')),
        ...this.formatBarToCSV(this.vendorsChartBar, this.$t('portalStats.vendorsFull')),
        ...this.formatBarToCSV(this.authChartBar, this.$t('portalStats.authFull')),
        ...this.formatBarToCSV(this.authenChartBar, this.$t('portalStats.authenFull'))
      ];
    },
    formatBarToExcel(graphData, graphName = 'data') {
      if (graphData.series.length === 0) {
        return { [graphName]: [] };
      }
      const result = [];
      result.push([graphName, ...graphData.xAxis.categories]);
      graphData.series.forEach((row) => result.push([row.name, ...row.data]));
      return { [graphName]: result };
    },
    formatPieToExcel(graphData, graphName = 'data') {
      const result = {};
      if (graphData.series[0].data.length === 0) {
        return {};
      }
      let name = 'Type';
      const count = this.$t('portalStats.count');
      const percent = this.$t('portalStats.percent');
      if (graphName === this.$t('portalStats.usersFull')) {
        name = this.$t('portalStats.users.type');
      }
      if (graphName === this.$t('portalStats.browserFull')) {
        name = this.$t('portalStats.browser');
      }
      if (graphName === this.$t('portalStats.os')) {
        name = this.$t('portalStats.OS');
      }
      if (graphName === this.$t('portalStats.locale.locale')) {
        name = this.$t('portalStats.locale.locale');
      }
      if (graphName === this.$t('portalStats.typeFull')) {
        name = this.$t('portalStats.type');
      }
      if (graphName === this.$t('portalStats.vendorsFull')) {
        name = this.$t('portalStats.vendors');
      }
      if (graphName === this.$t('portalStats.authFull')) {
        name = this.$t('portalStats.authenZ');
      }
      if (graphName === this.$t('portalStats.authenFull')) {
        name = this.$t('portalStats.authen.type');
      }
      result[name] = [];
      const itemsToExcel = result[name];
      itemsToExcel.push([name, count, percent]);
      for (const item of graphData.series[0].data) {
        let name = '';
        let count = '';
        let percent = '';

        if (item.name) {
          name = item.name;
        }

        if (item.amount) {
          count = item.amount;
        }

        if (item.y) {
          percent = item.y.toFixed(2);
        }
        itemsToExcel.push([name, count, percent]);
      }
      return result;
    },

    formatAllStatsToExcel() {
      return {
        ...this.formatBarToExcel(this.usersChartBar, this.$t('portalStats.newAndRepeatUsers')),
        ...this.formatBarToExcel(this.osChartBar, this.$t('portalStats.os')),
        ...this.formatBarToExcel(this.localeChartBar, this.$t('portalStats.locale.locale')),
        ...this.formatBarToExcel(this.typeChartBar, this.$t('portalStats.typeFull')),
        ...this.formatBarToExcel(this.vendorsChartBar, this.$t('portalStats.vendorsFull')),
        ...this.formatBarToExcel(this.authChartBar, this.$t('portalStats.authFull')),
        ...this.formatBarToExcel(this.authenChartBar, this.$t('portalStats.authenFull'))
      };
    }
  },
  // created() {
  //   if (this.portalProfilesList.length < 1) portalProfilesService.getPortalProfiles(this);
  //   // NOTE: getting data from /api/admin/range
  //   this.getRange(this.dataQuery);
  //   // this.getRangeForPreviousPeriod();
  // }
  created() {
    if (this.portalProfilesList.length < 1) portalProfilesService.getPortalProfiles(this);

    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }
      if (locationForFiltering === 'All locations') {
        this.filters.location = {};
        this.filters.with_childs = true;
      } else {
        this.filters.location = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
        this.filters.with_childs = withChildsForFiltering
      }
    }

    // NOTE: getting data from /api/admin/range
    this.getRange(this.dataQuery);
    // this.getRangeForPreviousPeriod();
  }
};
</script>

<style lang="scss" scoped>
.datepicker_height_select_another_period {
  .mx-input {
    height: 38px;
  }
}
.save-png-button-for-portal-stats {
  position: relative;
  font-size: 0.8rem;
  top: 2px;
}
.graph-block {
  padding: 20px 0 0 0;
}
.datepicker_width_full {
  width: 100%;
}

.traffic-profit {
  flex-grow: 1;
  margin-top: 10px;
  margin-bottom: 90px;
}

.category-profit {
  flex-grow: 1;
  margin-top: 20px;
}

.text-active {
  color: rgba(255, 143, 7, 1);
}

.text-loyalty {
  color: rgba(253, 216, 53, 1);
}

.text-returned {
  color: rgba(255, 143, 7, 0.8);
}

.text-new {
  color: #81c784;
}

.value {
  font-size: 1.7rem;

  &--big {
    font-size: 2.4rem;
  }

  &--large {
    font-size: 2rem;
  }

  &--title {
    font-size: 0.9rem;
  }
}

.card-new {
  border-top: 4px solid #81c784;
}

.card-returned {
  border-top: 4px solid rgba(255, 143, 7, 0.8);
}

.card-unique {
  border-top: 4px solid rgb(124, 181, 236);
}

.text-returned {
  color: rgba(255, 143, 7, 0.8);
}

.card-default {
  border-top: 4px solid rgba(183, 183, 183, 0.5);
}

.card-summary {
  margin-top: -30px;
}

.text-profit {
  font-size: 1.5rem;
}

.rateHalf {
  height: 300px;
}

.filter-table {
  width: 140px;
}

.text-muted {
  color: #94a0b2 !important;
}

.text-muted-light {
  color: #bcc5d0 !important;
}

/*.loader-backdrop {*/
/*  &:after {*/
/*    position: absolute;*/
/*    width: 100px;*/
/*    height: 100px;*/
/*    top: 10%;*/
/*    border: 5px solid #e6e6e6;*/
/*    border-top-color: #1bb3a0;*/
/*  }*/
/*}*/

.vendor-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  & tbody {
    display: block;
    width: 100%;
    overflow-y: auto;
    max-height: 450px;
  }

  & thead tr {
    display: block;
  }

  & th,
  & td {
    padding: 5px;
    text-align: left;
    width: 150px;
  }
  & .vendor-cell {
    width: 300px;
  }
}

.portal-stats-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  & tbody {
    display: block;
    width: 100%;
    /*overflow: auto;*/
    height: 450px;
    overflow-y: auto;
    max-height: 450px;
  }

  & thead tr {
    display: block;
  }

  & th,
  & td {
    padding: 5px;
    text-align: left;
    width: 200px;
  }
  & .type-cell {
    width: 250px;
  }
  & .type-cell-users {
    width: 300px;
  }
  & .type-cell-browsers {
    width: 150px;
  }
  & .type-cell-type {
    width: 300px;
  }
  & .type-cell-authz {
    width: 330px;
  }
}

.save-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
</style>

<style lang="scss">
  .download-button-in-PortalStatistics button{
        height: 40px !important;
    width: 100%;
  }
  .multiselect-in-PortalStatistics--filters {
  min-height: 23px !important;
}

.multiselect-in-PortalStatistics--filters .multi-select__control {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  max-height: 300px !important;
  min-height: 23px !important;
  align-items: center;
}

.multiselect-in-PortalStatistics--filters .multi-select__input {
  min-height: 23px !important;
  margin-bottom: 0px !important;
}

.multiselect-in-PortalStatistics--filters .arrow {
  min-height: 23px !important;
}

.multiselect-in-PortalStatistics--filters .multi-select__tag {
  margin-bottom: 0px;
  height: 22px !important;
}

    .input-group-for-location-select-in-PortalStatistics--filters {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;
  }

  .select-location-in-PortalStatistics--filters .select__list-container {
    width: 400px;
  }
.col-xs-5ths-for-portal-statistic-cards,
.col-sm-5ths-for-portal-statistic-cards,
.col-md-5ths-for-portal-statistic-cards,
.col-lg-5ths-for-portal-statistic-cards {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-5ths-for-portal-statistic-cards {
  width: 100%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-5ths-for-portal-statistic-cards {
    width: 50%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-5ths-for-portal-statistic-cards {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths-for-portal-statistic-cards {
    width: 20%;
    float: left;
  }
}
.loader-marketing:after {
  position: absolute;
  /*top: calc(50vh);*/
  top: 30px;
}

.submit-width_full {
  & button {
    // position: relative;
    margin-top: 28px;
    height: 40px;
    width: 100%;
  }
}
.custom-download-button-size-in-portal-stats button {
  padding: 0px;
  height: 20px;
  width: 20px;
}
.custom-download-button-size-in-portal-stats {
  top: 7px;
}
</style>
<style lang="scss" scoped>
.sms-Cost-rubles {
  font-size: 1.2rem;
  margin-left: -7px;
}
.sms-Cost-numbers-difference {
  margin-left: 3px !important;
}
.sms-Cost-numbers-difference-no-diff {
  /*margin-left: 3px !important;*/
}
.skeleton-placeholder-for-user-counters-in-portal-statistic {
  height: 135px;
}
.skeleton-placeholder-for-pie-charts-in-portal-statistic {
  min-height: 350px;
  /*border: rgba(128, 128, 128, 0.47) 0.1px dashed;*/
  position: relative;
}

.wrapper-for-skeleton-placeholder-pie-charts-in-portal-statistic {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  /*border: 0.5px solid rgba(0, 0, 0, 0.125);*/
  border: 0.5px solid rgba(0, 0, 0, 0.025);
  border-radius: 0.25rem;
  justify-content: center;
  margin-top: 1.5rem;
  padding-top: 30px;
}
.skeleton-placeholder-for-line-charts-in-portal-statistic {
  min-height: 380px;
  /*border: rgba(128, 128, 128, 0.47) 0.1px dashed;*/
  position: relative;
}

.wrapper-for-skeleton-placeholder-line-charts-in-portal-statistic {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  /*border: 0.5px solid rgba(0, 0, 0, 0.125);*/
  border: 0.5px solid rgba(0, 0, 0, 0.025);
  border-radius: 0.25rem;
  justify-content: center;
  margin-top: 1.5rem;
  padding-top: 30px;
}
.block-real-time-data-pie-and-table {
  position: relative;
}
.block-non-real-time-chart {
  position: relative;
}
.date-picker-for-another-period {
  min-width: 270px;
}
.value--big div {
  margin-left: 10px;
}
/*.custom-max-height-for-table-in-portal-stats-thead{*/
/*  display: block;*/
/*}*/
/*.custom-max-height-for-table-in-portal-stats-tbody{*/
/*  display: block;*/
/*  max-height: 450px;*/
/*  overflow-y: auto;*/
/*}*/
.disbale-with-childs {
  cursor: not-allowed;
}
.fix-z-index {
  z-index: 2;
}
.custom-move-right-block-real-time-data {
  /*position: relative;*/
  /*left: 50px*/
}

.custom-move-right-block-non-real-time-data {
  /*position: relative;*/
  /*left: 50px*/
}

.custom-move-left-block-real-time-data {
  /*position: relative;*/
  /*right: 45px*/
}

.custom-move-left-block-non-real-time-data {
  /*position: relative;*/
  /*right: 45px*/
}

.portal-statistics-block-real-time-data-table {
  position: relative;
  left: -20px;
}

.portal-statistics-block-real-time-data {
  /*border: 0.2px solid rgba(227, 232, 236, 0.53);*/
  width: 550px;
  min-width: 300px;
  padding: 10px;
  /*margin-top: 10px;*/
  /*margin-bottom: 10px;*/
}

.portal-statistics-block-non-real-time-data {
  /*border: 0.2px solid rgba(227, 232, 236, 0.53);*/
  width: 550px;
  min-width: 300px;
  padding: 10px;
  /*margin-top: 10px;*/
  /*margin-bottom: 10px;*/
}

@media (max-width: 1500px) {
  .portal-statistics-block-real-time-data {
    /*border: 0.2px solid rgba(227, 232, 236, 0.53);*/
    width: 480px;
    min-width: 300px;
    padding: 10px;
    /*margin-top: 10px;*/
    /*margin-bottom: 10px;*/
  }

  .portal-statistics-block-non-real-time-data {
    /*border: 0.2px solid rgba(227, 232, 236, 0.53);*/
    width: 480px;
    min-width: 300px;
    padding: 10px;
    /*margin-top: 10px;*/
    /*margin-bottom: 10px;*/
  }

  .portal-statistics-block-real-time-data-table {
    position: relative;
    left: -25px;
  }
}

@media (max-width: 1300px) {
  .portal-statistics-block-real-time-data {
    /*border: 0.2px solid rgba(227, 232, 236, 0.53);*/
    width: 700px;
    min-width: 450px;
    padding: 10px;
    /*margin-top: 10px;*/
    /*margin-bottom: 10px;*/
  }

  .portal-statistics-block-non-real-time-data {
    /*border: 0.2px solid rgba(227, 232, 236, 0.53);*/
    width: 700px;
    min-width: 450px;
    padding: 10px;
    /*margin-top: 10px;*/
    /*margin-bottom: 10px;*/
  }

  .portal-statistics-block-real-time-data-table {
    position: relative;
    left: 0px;
  }
}

.portal-statistics-block-real-time-data-hidden {
  visibility: hidden;
}

.portal-statistics-block-non-real-time-data-hidden {
  visibility: hidden;
}
</style>
<!--background-image: url(https://leroy-moskva.ru/wp-content/uploads/2018/06/Lerua-Merlen-Lefortovo-shema.jpg);-->
<!--background-size: contain;-->
